import * as React from 'react';

import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

import linkResolver from './.prismic/linkResolver';
import Page from './src/templates/page';
import BlogPost from './src/templates/blog_post';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: Page,
          blog_post: BlogPost,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);

// extracted by mini-css-extract-plugin
export var section = "styles-module--section--LSNdR";
export var container = "styles-module--container--suWmn";
export var step = "styles-module--step--nL3u+";
export var title = "styles-module--title--e2qne";
export var contactDetail = "styles-module--contactDetail--O6gmn";
export var addressTitle = "styles-module--addressTitle--rc-rL";
export var address = "styles-module--address--ceS55";
export var logoContainer = "styles-module--logoContainer--7g6Q2";
export var backAndForthRight = "styles-module--backAndForthRight--cf4eU";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--726yT";
export var animateBg = "styles-module--animateBg--G5J3M";
// extracted by mini-css-extract-plugin
export var newsletterForm = "styles-module--newsletterForm--HFYA2";
export var form = "styles-module--form--P0UzU";
export var field = "styles-module--field--YY3G1";
export var splitLeft = "styles-module--split-left--Chd94";
export var splitRight = "styles-module--split-right--03Ek8";
export var title = "styles-module--title--tqWPi";
export var label = "styles-module--label--eauSm";
export var formButton = "styles-module--formButton--PP34u";
export var backAndForthRight = "styles-module--backAndForthRight--BPfiD";
export var loader = "styles-module--loader--xKbS2";
export var arrow = "styles-module--arrow--dOMcO";
export var fieldError = "styles-module--fieldError--Oxuly";
export var successMessage = "styles-module--successMessage--IkEQ0";
export var active = "styles-module--active--irhlu";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--+iZvR";
export var animateBg = "styles-module--animateBg--lf6oj";
import React from 'react';
import { Section, Text } from 'components';
import * as styles from './styles.module.scss';

const BlogHeader = ({ title, tagline, description }) => {
  // content checks
  const titleHtml = title?.html;
  const descriptionHtml = description?.html;
  const taglineHtml = tagline?.html;

  return (
    <Section className={styles.section} sliceName="BlogHeader">
      {tagline?.text && <Text html={taglineHtml} h5 className={styles.tagline} />}
      {title?.text && <Text html={titleHtml} h2 className={styles.title} />}
      {description?.text && <Text html={descriptionHtml} p3 className={styles.content} />}
    </Section>
  );
};

export default BlogHeader;

// extracted by mini-css-extract-plugin
export var section = "styles-module--section--O6C1l";
export var container = "styles-module--container--PgRJG";
export var leftContainer = "styles-module--leftContainer--r-pwS";
export var rightContainer = "styles-module--rightContainer--hehhF";
export var link = "styles-module--link--ndlKg";
export var title = "styles-module--title--vlUJQ";
export var tagline = "styles-module--tagline--FRG-l";
export var content = "styles-module--content--amxlc";
export var itemContainer = "styles-module--itemContainer--D1OCA";
export var itemIndex = "styles-module--itemIndex--+E6kF";
export var itemIcon = "styles-module--itemIcon--lXHTZ";
export var itemContentContainer = "styles-module--itemContentContainer--VjGc+";
export var itemTitle = "styles-module--itemTitle--GDozG";
export var itemContent = "styles-module--itemContent--HymPu";
export var linkScroller = "styles-module--linkScroller---Hqkl";
export var itemLink = "styles-module--itemLink--yg+r7";
export var itemTitleIcons = "styles-module--itemTitleIcons--mer2M";
export var animateBg = "styles-module--animateBg--Ue0Q-";
export var backAndForthRight = "styles-module--backAndForthRight--Ph8H6";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--h1zeC";
// extracted by mini-css-extract-plugin
export var grid = "styles-module--grid--g3ybi";
export var left = "styles-module--left--WwG63";
export var tagline = "styles-module--tagline--sFen5";
export var title = "styles-module--title--xbINj";
export var content = "styles-module--content--kG39u";
export var downloadContainer = "styles-module--downloadContainer--XzbGV";
export var downloadLink = "styles-module--downloadLink--YxfKE";
export var button = "styles-module--button--IBKpX";
export var backAndForthRight = "styles-module--backAndForthRight--8YpBZ";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--uDecQ";
export var animateBg = "styles-module--animateBg--dGrHp";
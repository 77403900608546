import React, { useState } from 'react';
import { Section, Text, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Faqs = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { description, title, do_not_generate_schema: noSchema, theme } = primary;
  const titleHtml = title?.html;
  const descriptionHtml = description?.html;

  const [activeIndex, setActiveIndex] = useState([0]);

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((prevIndex) => prevIndex !== index);
      }
      return [...prevIndexes, index];
    });
  };

  const escapeDoubleQuotes = (str) => str.replace(/\\([\s\S])|(")/g, '\\$1$2');

  const schema = `
  <script type="application/ld+json">
    {
    "@context": "https://schema.org",
    "@type": "FAQsPageFragment",
    "mainEntity": [
      ${items.map(
    (item) => `
        {
          "@type": "Question",
          "name": "${item.question?.text}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${escapeDoubleQuotes(item.answer?.html)}"
          }
        }`
      )}]
    }
    </script>
  `;

  // THEME DEFINITION
  const themeStyles = {
    Black: 'black',
    'Off Black': 'offBlack',
    White: 'white',
    'Off White': 'offWhite',
  };

  return (
    <Section
      sliceName="Faqs"
      className={`${styles.faqAccordion}
        ${themeStyles[theme] || 'black'} faq-accordion`}
      containerClassName={styles.accordionContainer}
    >
      <OnVisible className={styles.textContainer} visibleClassName={styles.visible}>
        {titleHtml && <Text html={titleHtml} h3 className={styles.title} />}
        {descriptionHtml && <Text html={descriptionHtml} p3 className={styles.description} />}
      </OnVisible>

      <OnVisible className={styles.accordionItems} visibleClassName={styles.visible}>
        {items.map((item, index) => {
          const { question, answer } = item;
          const questionText = question?.text;
          const answerHtml = answer?.html;
          const isActive = activeIndex.includes(index);

          return (
            <div
              key={questionText}
              className={`${styles.accordionItem} ${isActive ? styles.active : styles.inactive}`}
              aria-hidden="true"
            >
              <button
                type="button"
                className={styles.questionContainer}
                onClick={(event) => handleOnClick(event, index)}
              >
                <Text html={question.html} className={styles.question} />
                <div className={styles.accordionItemToggleContainer}>
                  <div className={styles.accordionItemToggle} />
                </div>
              </button>
              {answerHtml && <div className={styles.answer} dangerouslySetInnerHTML={{ __html: answerHtml }} />}
            </div>
          );
        })}
      </OnVisible>
      {!noSchema && <div dangerouslySetInnerHTML={{ __html: schema }} />}
    </Section>
  );
};

export default Faqs;

import React, { useEffect, useState, useRef } from 'react';
import * as styles from './styles.module.scss';

const Video = ({ id, src, type = 'video/mp4', autoPlay, placeholder, className, setVideoRef }) => {
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = document.getElementById(id);
    video.addEventListener('loadeddata', () => setLoaded(true), false);
    // Force video to show after 4 seconds incase
    // loadeddata listener does not fire

    setTimeout(() => {
      if (!loaded) setLoaded(true);
    }, 4000);
  }, []);

  useEffect(() => {
    if (setVideoRef) {
      setVideoRef(videoRef);
    }
  }, [videoRef]);

  return (
    <div
      className={`video-bg-container ${className || ''}`}
      style={placeholder ? { background: `url('${placeholder}') center no-repeat` } : {}}
    >
      <video
        preload="true"
        autoPlay={autoPlay !== false}
        playsInline
        muted
        loop
        ref={videoRef}
        className="video"
        id={id}
        style={{ opacity: loaded ? 1 : 0 }}
        aria-label="Video from Pac Captial"
      >
        <source src={src} type={type} />
        Sorry, your browser doesn&#39;t support embedded videos.
      </video>
    </div>
  );
};

export default Video;

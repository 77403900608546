// extracted by mini-css-extract-plugin
export var postsContainer = "styles-module--postsContainer--zsHNx";
export var verticalVariant = "styles-module--verticalVariant--ee+GY";
export var titleContainer = "styles-module--titleContainer--5GfY2";
export var title = "styles-module--title--NK4AC";
export var tagline = "styles-module--tagline--HhPdt";
export var largeTitle = "styles-module--largeTitle--PR0Aq";
export var button = "styles-module--button--UTEmu";
export var backAndForthRight = "styles-module--backAndForthRight--+zpou";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--RbEvU";
export var animateBg = "styles-module--animateBg--XrtVB";
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import lunr from 'lunr';

const BlogSearchIndex = (WrappedComponent) => {
  const BlogSearchIndexComponent = (props) => {
    const [loading, setLoading] = useState(false);
    const [blogIndex, setBlogIndex] = useState(null);

    const builder = new lunr.Builder();

    const data = useStaticQuery(graphql`
      query SearchIndexPage {
        allPrismicBlogPost {
          nodes {
            uid
            data {
              author {
                url
                document {
                  ... on PrismicAuthor {
                    id
                    data {
                      author_image {
                        url
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
                          }
                        }
                      }
                      author_name {
                        text
                        html
                      }
                    }
                  }
                }
              }
              category {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      category_name {
                        html
                        text
                      }
                    }
                    uid
                  }
                }
              }
              featured_image {
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 382, height: 214, placeholder: BLURRED)
                  }
                }
              }
              format
              is_popular
              title {
                html
                text
              }
              date(formatString: "MMMM d, YYYY")
            }
          }
        }
      }
    `);

    const { allPrismicBlogPost = {} } = data;
    const { nodes = {} } = allPrismicBlogPost;
    // // START OF LUNR INDEXING
    //
    useEffect(() => {
      if (!blogIndex && allPrismicBlogPost) {
        setLoading(true);
        lunr(() => {
          builder.ref('uid');
          builder.field('title', { boost: 10 });
          builder.field('author', { boost: 7 });
          nodes.forEach(function (node) {
            builder.add(
              {
                uid: node?.uid,
                title: node?.data?.title?.text,
                author: node?.data?.author?.document?.data?.author_name?.text,
              },
              builder
            );
          });
        });
        const index = builder.build();
        setLoading(false);
        setBlogIndex(index);
      }
    }, [allPrismicBlogPost, blogIndex]);

    return (
      <WrappedComponent {...props} blogPosts={allPrismicBlogPost} blogIndex={blogIndex} blogIndexLoading={loading} />
    );
  };
  return BlogSearchIndexComponent;
};

export default BlogSearchIndex;

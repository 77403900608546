import React from 'react';
import { Logo, Section } from 'components';
import * as styles from './styles.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Section>
        <div className={`${styles.container}`}>
          <Logo className={styles.logo} />
        </div>
        <div className={styles.subContainer}>
          <span className={styles.sitemap}>
            © {new Date().getFullYear()} PAC Capital All Rights Reserved.{' '}
            <a href="https://mammoth.tech" title="Made by Mammoths" target="_blank" rel="noreferrer">
              Made by Mammoths
            </a>
          </span>
        </div>
      </Section>
    </footer>
  );
};

export default Footer;

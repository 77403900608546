import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { FormField, Link } from 'components';
import * as styles from './styles.module.scss';

const Form = ({
  content,
  location,
  prefilledEmail,
  fields,
  submitButton,
  title,
  formSparkId,
  showLabels,
  noArrow,
  onSubmit,
}) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const { errors, dirtyFields } = formState;

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  const onSubmitWrapper = async (values) => {
    setSubmitting(true);
    try {
      if (onSubmit) {
        await onSubmit(values);
      } else {
        const url = `https://submit-form.com/${formSparkId || '12tvq5xX'}`; // replace FORM_ID with formspark id
        const config = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(values),
        };
        const response = await fetch(url, config);
        if (response.ok) {
          setSubmitting(false);
        }
      }
      return navigate('/thank-you/');
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
      setSubmitting(false);
    }
  };

  return (
    <section>
      {title && <span className={styles.formTitle}>{title}</span>}
      {submissionError && <p>{submissionError}</p>}
      <form onSubmit={handleSubmit(onSubmitWrapper)} className={`form-comp ${styles.form}`}>
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        {fields.map((field) => {
          const hasError = dirtyFields[field.name] && errors[field.name];
          return (
            <div key={field.label} className={`${styles.field} ${field.className || ''}`}>
              {(field.type === 'checkbox' || showLabels) && <span className={styles.label}>{field.label}</span>}
              <FormField {...field} register={register} />
              <input
                {...register('_gotcha')}
                type="checkbox"
                name="_gotcha"
                style={{ display: 'none' }}
                tabIndex="-1"
                autoComplete="off"
              />
              <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                {hasError && (
                  <span className={styles.validationMessage}>
                    {field.validationMessage || 'This field is required'}
                  </span>
                )}
              </div>
            </div>
          );
        })}
        <div className={`submit-container ${styles.buttonContainer}`}>
          <button type="submit" aria-label="submit" className={`${styles.formButton}`} disabled={submitting}>
            <Link withArrow={!noArrow} className="button">
              {submitting ? 'Submitting' : submitButton || 'Send enquiry'}
            </Link>
          </button>
        </div>
      </form>
    </section>
  );
};

export default Form;

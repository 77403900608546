import React, { useState, useEffect } from 'react';
import * as styles from './styles.module.scss';

const Hamburger = ({ onClick, className, active }) => (
  <div className={`${className || ''} ${styles.hamburger} ${active ? styles.activeBurger : ''}`} onClick={onClick}>
    <span />
    <span />
    <span />
  </div>
);

export default Hamburger;

import React from 'react';
import { Link } from 'components';
import { siteName } from '../../../config/website';
import * as styles from './styles.module.scss';

const Logo = ({ dark, mobile, changeOnMobile, icon, className, id }) => (
  <Link id={id || ''} className={`${className ? className : ''} ${changeOnMobile && styles.changeOnMobile} ${mobile || icon && styles.mobile} ${styles.logo}`} to="/">
    {siteName}
  </Link>
);

export default Logo;

import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const AnchorTitle = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title1: title } = primary;

  const titleHtml = title?.html;
  const titleText = title?.text;
  const anchorId = titleText ? titleText.replace(/\s+/g, '-').toLowerCase() : '';

  return (
    <div className={styles.anchorTitle} id={anchorId}>
      <Section noContainer className={styles.section}>
        {titleHtml && <div className={styles.anchorTitleText} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      </Section>
    </div>
  );
};

export default AnchorTitle;

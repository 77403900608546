// extracted by mini-css-extract-plugin
export var container = "styles-module--container--u3QNS";
export var imageContainer = "styles-module--imageContainer--bGbcx";
export var imageContainerVisible = "styles-module--imageContainerVisible--d3UMR";
export var textContainer = "styles-module--textContainer--SbMof";
export var textContainerVisible = "styles-module--textContainerVisible--5f-cn";
export var profilesContainer = "styles-module--profilesContainer--VMud8";
export var bodyText = "styles-module--bodyText--EFsC6";
export var image = "styles-module--image--NJVp3";
export var name = "styles-module--name--cxKVD";
export var nameVisible = "styles-module--nameVisible--Kz6OB";
export var role = "styles-module--role--MDIiP";
export var roleVisible = "styles-module--roleVisible--V5F+F";
export var socials = "styles-module--socials--HEw-l";
export var socialsVisible = "styles-module--socialsVisible--jovY3";
export var email = "styles-module--email--wLIHV";
export var linkedin = "styles-module--linkedin--8Rqbk";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--TBM39";
export var backAndForthRight = "styles-module--backAndForthRight--Rq92G";
export var animateBg = "styles-module--animateBg--BPW+I";
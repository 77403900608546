import React from 'react';
import { Image, Section, Link } from 'components';
import * as styles from './styles.module.scss';

const FullScreenImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const { image, link, aspect_ratio: aspectRatio, parallax } = primary;

  const aspectClassNameMap = {
    '21:9': styles.aspect21x9,
    '16:9': styles.aspect16x9,
    '4:3': styles.aspect4x3,
  };

  const aspectClassName = aspectClassNameMap[aspectRatio] || '';
	const parallaxClassName = parallax ? styles.parallax : '';

  return (
    <Section className={`${styles.section} ${aspectClassName} ${parallaxClassName}`} noContainer>
      {link?.url ? (
        <Link to={link.url} className={styles.link}>
          <Image image={image} className={styles.image} />
        </Link>
      ) : (
        <Image image={image} className={styles.image} />
      )}
    </Section>
  );
};

export default FullScreenImage;

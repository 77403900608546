// extracted by mini-css-extract-plugin
export var form = "styles-module--form--wiw5P";
export var formTitle = "styles-module--formTitle--q4RCA";
export var field = "styles-module--field--ltA6u";
export var buttonContainer = "styles-module--buttonContainer--jUfzX";
export var formButton = "styles-module--formButton---rmdF";
export var label = "styles-module--label--F4qoU";
export var fieldError = "styles-module--fieldError--++zwb";
export var backAndForthRight = "styles-module--backAndForthRight--9kjcp";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--eE2+r";
export var animateBg = "styles-module--animateBg--MrY5M";
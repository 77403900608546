import React, { Fragment } from 'react';
import { Section, Image, Text, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const IconsList = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  const titleHtml = title?.html;

  return (
    <Section className={`${styles.section} icon-list`} containerClassName={styles.container} sliceName="IconsList">
      <OnVisible visibleClassName={styles.visibleContainer} threshold={0.5}>
        <Text html={titleHtml} h4 className={styles.title} />
        <div className={styles.grid}>
          {items.map((item, index) => {
            const { icon, icon_title: iconTitle } = item;
            return (
              <Fragment key={iconTitle}>
                <div className={styles.iconContainer} style={{ transitionDelay: `${index * 0.2}s` }}>
                  {icon?.url && <Image className={styles.icon} image={icon} />}
                  {iconTitle?.text && <Text html={iconTitle.html} className={styles.iconTitle} />}
                </div>
              </Fragment>
            );
          })}
        </div>
      </OnVisible>
    </Section>
  );
};

export default IconsList;

// extracted by mini-css-extract-plugin
export var section = "styles-module--section--GvaXk";
export var imageLeft = "styles-module--imageLeft--5R3ky";
export var grid = "styles-module--grid--khRqW";
export var tagline = "styles-module--tagline--yJxUd";
export var title = "styles-module--title--CTayp";
export var content = "styles-module--content--WDZGs";
export var text = "styles-module--text--qA7u6";
export var image = "styles-module--image--CrmpU";
export var backAndForthRight = "styles-module--backAndForthRight--odAG9";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--D7Tki";
export var animateBg = "styles-module--animateBg--VaUBC";
import React from 'react';
import { ContactForm, InvestForm, SubscribeForm } from 'components';
import * as styles from './styles.module.scss';

const FormSlice = (props) => {
  const {
    data: {
      primary: { type, form_title: title, form_button_text: button },
    },
    location,
  } = props;

  const titleText = title?.text;
  const buttonText = button?.text;

  return (
    <>
      {type === 'Contact' && <ContactForm title={titleText} buttonText={buttonText} />}
      {type === 'Invest' && <InvestForm />}
      {type === 'Subscribe' && <SubscribeForm location={location} title={titleText} buttonText={buttonText} />}
    </>
  );
};

export default FormSlice;

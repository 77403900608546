import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Slice, FooterCta, PostGrid, Section } from 'components';
import * as styles from './page.module.scss';

const Page = ({ location, data }) => {
  const { page, recentPosts: recentPostsObj } = data;
  const { data: pageData, first_publication_date: publishedDate, last_publication_date: updatedDate } = page;

  const recentPostsNodes = recentPostsObj?.nodes || [];

  const {
    body: sliceData,
    prismic_reference: pageName,
    transparent_header: transparentHeader,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    breadcrumbs,
    schema,
  } = pageData;

  const seo = {
    title: metaTitle.text || `${pageName?.text} | PAC Capital`,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    schema: schema?.text,
    publishedDate,
    updatedDate,
  };

  const recentPosts = recentPostsNodes.slice(0, 6);
  const isHome = location?.pathname === '/';

  return (
    <Layout location={location} seo={seo} transparentHeader={transparentHeader}>
      <div>
        {sliceData.map((slice, index) => (
          <Slice
            key={slice.id}
            data={slice}
            location={location}
            isFirstSlice={index === 0}
            breadcrumbs={breadcrumbs}
            isPage
          />
        ))}
        {/* {isHome && (
          <>
            <Section className={styles.postContainer}>
              <PostGrid
                posts={recentPosts}
                title="PAC Insights"
                tagline="Find knowledge and advice to invest better."
                categoryLink="/insights"
                categoryLinkText="View more insights"
              />
            </Section>
          </>
        )} */}
        <FooterCta />
      </div>
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      _previewable
      data {
        prismic_reference {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        breadcrumbs {
          breadcrumb_link {
            url
          }
          breadcrumb_title {
            text
          }
        }
        transparent_header
        body {
          ...TwoColsTextMediaPageFragment
          ...SingleColumnTextPageFragment
          ...FeatureCarouselFragment
          ...VideoPageFragment
          ...TwoColTitleTextPageFragment
          ...TwoColTextImagePageFragment
          ...IconsListPageFragment
          ...TitleLargeImageCtaPageFragment
          ...DownloadsPageFragment
          ...VerticalScrollerPageFragment
          ...TwoColsTextPercentageCirclesPageFragment
          ...TeamProfilesPageFragment
          ...MapPage
          ...FormPage
          ...PageWysiwyg
          ...FAQsPageFragment
          ...FullScreenImage
          ...TeamMemberBio
        }
      }
    }
    recentPosts: allPrismicBlogPost(sort: { fields: data___date, order: DESC }, limit: 6) {
      nodes {
        uid
        data {
          author {
            url
            document {
              ... on PrismicAuthor {
                id
                data {
                  author_image {
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
                      }
                    }
                  }
                  author_name {
                    text
                    html
                  }
                }
              }
            }
          }
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  category_name {
                    html
                    text
                  }
                }
                uid
              }
            }
          }
          featured_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
              }
            }
          }
          format
          is_popular
          title {
            html
            text
          }
          date(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
`;

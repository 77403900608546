import React, { Fragment, useState, useEffect } from 'react';
import { Link, Text, OnVisible, Form } from 'components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { ReactComponent as Twitter } from 'images/icons/icon-twitter.svg';
import { ReactComponent as LinkedIn } from 'images/icons/icon-linkedin.svg';
import { ReactComponent as Facebook } from 'images/icons/facebook.svg';
import { ReactComponent as CopyLink } from 'images/icons/share-link.svg';

import * as styles from './styles.module.scss';

const NewsletterSignUp = ({ cta, darkBg, className, location, isBlogPost }) => {
  // data
  const footerSettingsQuery = graphql`
    query {
      ...FooterSettings
    }
  `;

  const { footerSettings } = useStaticQuery(footerSettingsQuery);
  const { data = {} } = footerSettings;
  const { linkedin_link: linkedInLink, twitter_link: twitterLink } = data;

  // share to socials logic
  const currentPath = location?.pathname;
  const rootPath = 'https://paccapital.com.au';

  const shareLinks = {
    twitter: { shareLink: `https://twitter.com/intent/tweet?url=${rootPath}${currentPath}` },
    linkedIn: { shareLink: `https://www.linkedin.com/shareArticle?mini=true&url=${rootPath}${currentPath}` },
  };
  // copy link logic
  const [url, setUrl] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  // SOCIAL LINKS
  const socialLinks = [
    {
      comp: <Twitter className={`social-icon ${styles.icon}`} />,
      link: isBlogPost ? shareLinks.twitter.shareLink : twitterLink?.url,
    },
    {
      comp: <Facebook className={`social-icon ${styles.icon}`} />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${rootPath}${currentPath}`,
    },
    {
      comp: <LinkedIn className={`social-icon ${styles.icon}`} />,
      link: isBlogPost ? shareLinks.linkedIn.shareLink : linkedInLink?.url,
    },
  ];

  if (!cta) return null;

  const { sign_up_content: content, sign_up_title: title } = cta;
  // form fields
  const fields = [
    {
      type: 'text',
      name: 'name',
      label: 'Name',
      className: 'split-left',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'company',
      placeholder: '',
      label: 'Company',
      className: 'split-right',
      defaultValue: '',
    },
    {
      type: 'email',
      name: 'email',
      label: 'Email address',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
  ];

  const onSubmit = (values) => {
    navigate(`/subscribe?name=${values.name}&company=${values.company}&email=${values.email}`);
  };

  return (
    <OnVisible
      className={`${className || ''} ${darkBg ? styles.darkBg : ''} ${styles.container}`}
      visibleClassName={styles.visibleContainer}
      threshold={1}
    >
      <Text html={title?.html} h4 className={styles.title} />
      <span className={styles.content}>{content.text}</span>
      {/* FORM */}
      <Form onSubmit={onSubmit} fields={fields} showLabels submitButton="Subscribe" noArrow />
      {/* SOCIALS */}
      <div className={styles.socials}>
        {isBlogPost && <span className={styles.shareText}>{linkCopied ? 'Copied!' : 'Share'}</span>}
        <div className={styles.iconsContainer}>
          {isBlogPost && (
            <div
              onClick={() => handleCopyToClipboard()}
              className={`social-link ${linkCopied ? 'copied' : ''} ${styles.iconContainer}`}
            >
              <CopyLink className={`social-icon ${styles.icon}`} />
            </div>
          )}
          {socialLinks.map((item, index) => {
            const { comp, link } = item;
            return (
              <Fragment key={link}>
                {link && (
                  <Link to={link} className={styles.iconContainer} style={{ transitionDelay: `${index * 0.2}s` }}>
                    {comp}
                  </Link>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </OnVisible>
  );
};

export default NewsletterSignUp;

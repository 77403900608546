import React from 'react';
import { Section, Link, Text } from 'components';
import * as styles from './styles.module.scss';

const Downloads = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, title, theme } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  // THEME DEFINITION
  const themeStyles = {
    Black: 'black',
    'Off Black': 'offBlack',
    White: 'white',
    'Off White': 'offWhite',
  };

  return (
    <Section
      className={`${themeStyles[theme] || 'black'} ${styles.section}`}
      containerClassName={styles.container}
      sliceName="Downloads"
    >
      <div className={styles.grid}>
        <Text html={titleHtml} h3 className={styles.title} />
      </div>
      <div className={styles.grid}>
        <Text html={contentHtml} p2 className={styles.content} />
        {items && (
          <div className={styles.links}>
            {items.map((item) => {
              const { download, download_text: downloadText } = item;
              return (
                <Link className={`cta ${styles.cta}`} to={download?.url} withArrow>
                  {downloadText?.text}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Downloads;

import React from 'react';
import { Section, OnVisible, Text, Button, Link } from 'components';
import { ReactComponent as PacLogo } from 'images/logo-mobile.svg';
import * as styles from './styles.module.scss';

const TeamMemberBio = (props) => {
  const { data } = props;
  const { primary } = data;
  const { name, title, headline, bio, quote, link, linkedin, email } = primary;

  const nameHtml = name?.html;
  const titleText = title?.text;
  const headlineHtml = headline?.html;
  const bioHtml = bio?.html;
  const quoteHtml = quote?.html;
  const linkUrl = link?.url;

  return (
    <Section className={`${styles.section}`} containerClassName={styles.container}>
      <div className={styles.left}>
        <div className={styles.nameContainer}>
          {nameHtml && <Text html={nameHtml} className={styles.name} h3 useOnVisible />}
          {titleText && (
            <OnVisible className={styles.titleContainer}>
              <PacLogo className={styles.logo} />
              <Text text={titleText} p2 />
            </OnVisible>
          )}
        </div>
        {headlineHtml && <Text html={headlineHtml} className={styles.headline} h5 useOnVisible />}
        {bioHtml && <Text html={bioHtml} className={styles.bio} useOnVisible />}
      </div>
      <div className={styles.right}>
        {quoteHtml && <Text html={quoteHtml} className={styles.quote} p2 useOnVisible />}
        {linkUrl && (
          <OnVisible className={styles.buttonContainer}>
            <Button to={linkUrl} className={styles.button}>
              Get in Touch
            </Button>
            {(linkedin?.url || email?.text) && (
              <div className={styles.socials} visibleClassName={styles.socialsVisible}>
                {linkedin?.url && <Link to={linkedin?.url} className={styles.linkedin} />}
                {email?.text && <a href={`mailto:${email?.text}`} className={styles.email} />}
              </div>
            )}
          </OnVisible>
        )}
      </div>
    </Section>
  );
};

export default TeamMemberBio;

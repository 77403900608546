// extracted by mini-css-extract-plugin
export var faqAccordion = "styles-module--faqAccordion--YqeBK";
export var twoColumns = "styles-module--twoColumns--ovw4M";
export var accordionContainer = "styles-module--accordionContainer--bb3s9";
export var textContainer = "styles-module--textContainer--XcX1J";
export var title = "styles-module--title--1gC8t";
export var description = "styles-module--description--6Z8so";
export var accordionItems = "styles-module--accordionItems--Nsr85";
export var accordionItem = "styles-module--accordionItem--ixyTr";
export var question = "styles-module--question--QEg6R";
export var answer = "styles-module--answer--9vqyu";
export var visible = "styles-module--visible--zaxeZ";
export var questionContainer = "styles-module--questionContainer--uZo2b";
export var animateBg = "styles-module--animateBg--ghkPb";
export var accordionItemToggleContainer = "styles-module--accordionItemToggleContainer--GxHUB";
export var accordionItemToggle = "styles-module--accordionItemToggle--jitQl";
export var inactive = "styles-module--inactive--RoMXT";
export var active = "styles-module--active--Op4VJ";
export var backAndForthRight = "styles-module--backAndForthRight--0uPdo";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--UrHsX";
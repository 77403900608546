module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Wealth Management & Investment Experts | P.A.C. Capital', // Default Site Title used for SEO & PWA
  description:
    'PAC Capital is a privately owned, independent investment manager offering a service based on independence, transparency and care.', // Default Site Decription used for SEO
  siteName: 'PAC Capital', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_AU', // Facebook Language
  socialLinks: [
    'https://www.instagram.com/pac.capital/',
    'https://www.linkedin.com/company/p-a-c-capital',
    'https://twitter.com/PAC_Capital_',
    'https://m.facebook.com/PACCapitalPtyLtd/?ref=py_c',
    'https://www.youtube.com/channel/UC_rAcLfVPkTJoGtNscg83KA/videos',
  ], // Array of social links (facebook, insta, etc)
  // JSONLD / Manifest
  icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
  shortName: 'PAC Capital', // shortname for manifest. MUST be shorter than 12 characters
  author: 'PAC Capital', // Author for schemaORGJSONLD
  themeColor: '#000000', // PWA Icon background & address bar colour if installed on desktop
  backgroundColor: '#000000', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet

  twitter: '', // Twitter Username
};

import React, { useRef, useEffect, useState } from 'react';
import { Section, Link, Image, Video, OnVisible, TypeScroll } from 'components';
import Typed from 'react-typed';
import * as styles from './styles.module.scss';

const TwoColsTextMedia = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    title,
    byline,
    subtext,
    video,
    image,
    link,
    link_title: linkTitle,
    theme,
    wide_grid: wideGrid,
    media_on_left: mediaOnLeft,
    pac_feature: pacFeature,
  } = primary;

  const isBrandedVideo = video?.url?.includes('PAC+Animation');

  // Determine background color
  const themeStyles = {
    Black: { bg: 'black', arrow: 'white' },
    'Off-Black': { bg: 'offBlack', arrow: 'white' },
    White: { bg: 'white', arrow: 'gradient' },
    'Off-White': { bg: 'offWhite', arrow: 'gradient' },
  };

  // VIDEO CONTROLS
  const [videoRef, setVideoRef] = useState(null);
  const [visible, setVisible] = useState(false);

  // STOP TYPED ANIMATION ON PAGE LOAD
  useEffect(() => {
    typed?.current?.typed.stop();
  }, []);

  // TRIGGER PLAY OF VIDEO AND TYPED ON VISIBLE
  useEffect(() => {
    if (visible) {
      typed?.current?.typed.start();
    }
  }, [visible]);

  const typed = useRef();

  const handleOnChange = (step) => {
    const videoEl = videoRef?.current;
    const startIntervals = [0, 5.2, 10];
    if (videoEl && !isBrandedVideo) {
      videoEl.currentTime = startIntervals[step] || 0;
      videoEl.play();
    }
  };

  // PAC WORD SCROLLER SLICE
  if (pacFeature) {
    return (
      <Section
        className={`${styles.section} ${isBrandedVideo ? null : styles.unbrandedVideo} ${
          themeStyles[theme]?.bg || 'black'
        }`}
        containerClassName={styles.container}
        sliceName="TwoColsTextMedia"
        largeContainer={wideGrid && wideGrid}
      >
        <div className={styles.leftContainerPacFeature}>
          {byline && <span className={styles.byline}>{byline?.text}</span>}
          {!title?.text || title?.text?.legnth < 3 ? (
            <h1 className={styles.title}>
              Grow your wealth through{' '}
              <div className={styles.typeScroll}>
                <TypeScroll
                  words={['Persistence', 'Ambition', 'Consistency']}
                  speed={5000}
                  triggerPlay={visible}
                  onChange={handleOnChange}
                />
              </div>
            </h1>
          ) : (
            <h1 className={styles.title}>{title?.text}</h1>
          )}
          {subtext?.text && <p className={styles.subtext}>{subtext.text}</p>}
          {link?.url && (
            <Link to={link?.url} className={styles.link} arrow="gradient">
              {linkTitle?.text !== '' ? linkTitle?.text : 'Explore'}
            </Link>
          )}
        </div>
        <div className={styles.rightContainerPacFeature}>
          <>
            {video?.url && (
              <OnVisible onChange={setVisible} triggerOnce={false}>
                <div className={styles.videoContainer}>
                  <Video
                    className={styles.video}
                    id={data?.id}
                    setVideoRef={setVideoRef}
                    src={video?.url}
                    loop={false}
                  />
                </div>
              </OnVisible>
            )}
            {image?.url && <Image className={styles.image} image={image?.url} />}
          </>
        </div>
      </Section>
    );
  }

  // MAIN HEADER SLICE
  return (
    <Section
      className={`${styles.sectionHeader} ${themeStyles[theme]?.bg || 'black'}`}
      containerClassName={styles.container}
      sliceName="TwoColsTextMedia"
      largeContainer={wideGrid && wideGrid}
    >
      <div className={styles.leftContainer}>
        {byline?.text && <h5 className={styles.byline}>{byline.text}</h5>}
        {title?.text && <h1 className={styles.title}>{title.text}</h1>}
        {subtext?.text && <p className={styles.subtext}>{subtext.text}</p>}
        {link?.url && (
          <Link to={link?.url} className={styles.link} arrow="gradient">
            {linkTitle?.text !== '' ? linkTitle?.text : 'Explore'}
          </Link>
        )}
      </div>
      <div className={styles.rightContainer}>
        <>
          {video?.url && (
            <OnVisible className={styles.videoContainer} onChange={setVisible}>
              <Video className={styles.video} id={data?.id} setVideoRef={setVideoRef} src={video?.url} autoPlay />
            </OnVisible>
          )}
          {image?.url && <Image className={styles.image} image={image} />}
        </>
      </div>
    </Section>
  );
};

export default TwoColsTextMedia;

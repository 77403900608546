import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { Image, Section, Link } from 'components';
import * as styles from './styles.module.scss';

const Map = () => {
  // MAPBOX LOGIC
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;

    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [151.21209179448616, -33.86562111638321],
      zoom: 16,
    });
    const marker1 = new mapboxgl.Marker({
      color: '#5B45FF',
    })
      .setLngLat([151.21209179448616, -33.86562111638321])
      .addTo(map.current);
  }, []);

  return (
    <Section className={`pac-map-box ${styles.section}`}>
      <div>
        <div ref={mapContainer} className={styles.mapContainer} />
      </div>
    </Section>
  );
};

export default Map;

import React, { Fragment } from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const FooterColumn = (props) => {
  const { classNamePrefix = 'main', links, title } = props;
  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';
  return (
    <div className={styles.footerColumn}>
      <p className={styles.title}>{title && title}</p>
      {links?.length &&
        links.map((link) => {
          const slug = link?.link?.url;
          const text = link?.link_text?.text;
          return (
            <Fragment key={text}>
              {text && (
                <Link className={styles.link} to={`${slug}`}>
                  {text}
                </Link>
              )}
            </Fragment>
          );
        })}
    </div>
  );
};

export default FooterColumn;

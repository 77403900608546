import React, { Fragment } from 'react';
import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import { ReactComponent as Twitter } from 'images/icons/icon-twitter.svg';
import { ReactComponent as LinkedIn } from 'images/icons/icon-linkedin.svg';
import { ReactComponent as Facebook } from 'images/icons/facebook.svg';
import { ReactComponent as YouTube } from 'images/icons/icon-youtube.svg';
import { ReactComponent as Instagram } from 'images/icons/icon-instagram.svg';
import { Link, Section, Text, Form } from 'components';
import * as styles from './styles.module.scss';

const ContactForm = ({ title, buttonText, location }) => {
  // data
  const contactQuery = useStaticQuery(graphql`
    query contactData {
      prismicSettings {
        data {
          phone: phone_number {
            text
            html
          }
          email {
            text
            html
          }
          address {
            html
            text
          }
          twitter_link {
            url
          }
          linkedin_link {
            url
          }
          instagram_link {
            url
          }
          facebook_link {
            url
          }
          youtube_link {
            url
          }
        }
      }
    }
  `);

  const {
    prismicSettings: { data: contactData },
  } = contactQuery;

  const {
    phone,
    email,
    address,
    twitter_link: twitter,
    linkedin_link: linkedin,
    instagram_link: instagram,
    facebook_link: facebook,
    youtube_link: youtube,
  } = contactData;

  const socials = [
    { comp: <Twitter className={styles.socialIcon} />, link: twitter?.url },
    { comp: <LinkedIn className={styles.socialIcon} />, link: linkedin?.url },
    { comp: <Instagram className={styles.socialIcon} />, link: instagram?.url },
    { comp: <YouTube className={styles.socialIcon} />, link: youtube?.url },
    { comp: <Facebook className={styles.socialIcon} />, link: facebook?.url },
  ];

  const fields = [
    {
      type: 'text',
      name: 'name',
      placeholder: 'Name',
      label: 'First name',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'tel',
      name: 'phone',
      placeholder: 'Phone number',
      label: 'Best Phone number',
      defaultValue: '',
      validation: { required: true, minLength: 8 },
      validationMessage: 'Please enter a valid number',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email address',
      label: 'Email address',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: 'Your message',
      label: 'Enter a message',
      defaultValue: '',
    },
  ];

  const handleOnSubmit = async (values) =>
    axios.post('/api/hubspot/contact', {
      ...values,
      pageUri: location?.href || 'Unknown',
      pageName: location?.pathname || 'Unknown',
    });

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Form">
      <div>
        {/* phone */}
        {phone?.text && (
          <div className={styles.contactSection}>
            <span className={styles.title}>Call us</span>
            <Text className={styles.contactDetail} html={phone.html} p2 />
          </div>
        )}
        {email?.text && (
          <div className={styles.contactSection}>
            <span className={styles.title}>Email us</span>
            <Text className={styles.contactDetail} html={email.html} p2 />
          </div>
        )}
        {address?.text && (
          <div className={styles.contactSection}>
            <span className={styles.title}>Meet us</span>
            <Text className={styles.contactDetail} html={address.html} p2 />
          </div>
        )}
        <div className={styles.contactSection}>
          <span className={styles.title}>Follow us</span>
          <div className={styles.socials}>
            {socials.map((social) => {
              const { comp, link } = social;
              if (!link) return null;
              return (
                <Fragment key={link}>
                  <Link to={link}>{comp}</Link>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.formContainer}>
        <Form fields={fields} onSubmit={handleOnSubmit} title={title || ''} buttonText={buttonText || 'Contact Us'} />
      </div>
    </Section>
  );
};

export default ContactForm;

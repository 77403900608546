import React from 'react';
import { OnVisible } from 'components';
import classNames from '../../helpers/classNames';
import * as styles from './styles.module.scss';

const Text = ({ html, text, h1, h2, h3, h4, h5, h6, p1, p2, p3, className, visibleClassName, useOnVisible }) => {
  if (!html && !text) return null;

	const wrappedText = text ? `<div>${text}</div>` : null;

  const combinedClassNames = classNames(styles.text, {
    [className]: className,
    [styles.h1]: h1,
    [styles.h2]: h2,
    [styles.h3]: h3,
    [styles.h4]: h4,
    [styles.h5]: h5,
    [styles.h6]: h6,
    [styles.p1]: p1,
    [styles.p2]: p2,
    [styles.p3]: p3,
  });

  if (visibleClassName || useOnVisible) {
    return (
      <OnVisible
        className={combinedClassNames}
        visibleClassName={visibleClassName}
        dangerouslySetInnerHTML={{ __html: html || wrappedText }}
      />
    );
  }

  return <div className={combinedClassNames} dangerouslySetInnerHTML={{ __html: html || wrappedText }} />;
};

export default Text;

import React, { Fragment } from 'react';
import './styles.scss';

const FormField = ({
  name,
  type,
  label,
  options,
  placeholder,
  defaultValue,
  autoFocus,
  handleChange,
  register,
  validation,
}) => {
  if (type === 'textarea') {
    return (
      <textarea
        {...register(name, validation)}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        aria-label={name}
      />
    );
  }
  if (type === 'select') {
    return (
      <select name={name} {...register(name, validation)} aria-label={name}>
        {options.map((option, index) => (
          <Fragment key={option}>
            {index === 0 && option === 'Select your country' ? (
              <option selected disabled hidden value={option} defaultValue={defaultValue} aria-label={option}>
                {option}
              </option>
            ) : (
              <option value={option} defaultValue={defaultValue} key={option} aria-label={option}>
                {option}
              </option>
            )}
          </Fragment>
        ))}
      </select>
    );
  }

  if (type === 'radio' || type === 'checkbox') {
    return (
      <div className={`${type}-buttons`}>
        {options.map((option) => (
          <div key={option} className={`${type}-button`}>
            <input
              {...register(name, validation)}
              type={type}
              id={option}
              name={name}
              value={option}
              onChange={handleChange}
              aria-label={option}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    );
  }
  return (
    <input
      {...register(name, validation)}
      type={type}
      name={name}
      id={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      aria-label={name}
    />
  );
};

export default FormField;

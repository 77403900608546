import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section } from 'components';
import { ReactComponent as PacIcon } from '../../images/logo-mobile-light.svg';
import * as styles from './styles.module.scss';

const InvestForm = () => {
  const investQuery = useStaticQuery(graphql`
    query investData {
      prismicSettings {
        data {
          steps {
            step_text {
              text
              html
            }
          }
        }
      }
    }
  `);

  const {
    prismicSettings: { data: contactData },
  } = investQuery;

  const { steps } = contactData;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="Form">
      <div>
        {steps.map((item, index) => {
          const {
            step_text: { html: stepHtml },
          } = item;
          return (
            <div className={styles.step} key={item.step_text.text}>
              <span className={styles.title}>Step {index + 1}</span>
              <div dangerouslySetInnerHTML={{ __html: stepHtml }} className={styles.contactDetail} />
            </div>
          );
        })}
      </div>
      <div className={styles.logoContainer}>
        <PacIcon />
      </div>
    </Section>
  );
};

export default InvestForm;

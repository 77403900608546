import React, { useState, useEffect, Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Link, CategoryHamburger, SearchBar } from 'components';
import { ReactComponent as Search } from 'images/icons/magnifying-glass.svg';

import * as styles from './styles.module.scss';

const CategoryNav = () => {
  const categoryQuery = graphql`
    query {
      ...CategoryQuery
    }
  `;
  const {
    categoryData: { nodes: categories = {} },
  } = useStaticQuery(categoryQuery);

  const [active, setActive] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [bodyLocked, setBodyLock] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);

  const onClick = (event) => {
    event.preventDefault();
    setActive(!active);
  };

  // C L O S E   O N   E S C   A N D   B O D Y   L O C K
  const handleEscKey = (event) => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setActive(false);
    }
  };

  const lockBody = () => {
    // lock body while show nav true
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when show nav false, unlock body
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (active && !bodyLocked) lockBody();
    if (!active && bodyLocked) unlockBody();
  }, [active]);

  // toggle classes
  const hamburgerOpenClass = active ? styles.hamburgerOpen : '';

  // SEARCH FUNCTIONALITY
  const handleShowSearch = (event) => {
    if (event) event.preventDefault();
    const headerElement = document.querySelector('.pac-header-nav');
    if (displaySearch) {
      headerElement.style.marginBottom = 0;
      setDisplaySearch(false);
    } else {
      headerElement.style.marginBottom = 0;
      setDisplaySearch(true);
    }
  };

  const searchVisible = displaySearch ? 'show-search' : 'hide-search';

  return (
    <>
      <Section
        className={`pac-blog-nav ${styles.section} ${searchVisible} ${hamburgerOpenClass}`}
        containerClassName={styles.container}
        largeContainer
      >
        <div className={styles.navContainer}>
          <span className={styles.categoryTitle}>Categories</span>
          <CategoryHamburger onClick={onClick} active={active} className={styles.hamburger} />
        </div>
        <div className={styles.linksContainer}>
          <Link to="/insights" className={styles.category}>
            All
          </Link>
          {categories.map((category, index) => {
            const {
              data: {
                category_name: { text: categoryText },
              },
              uid,
            } = category;
            return (
              <Fragment key={uid}>
                <Link
                  to={`/insights/${uid}/`}
                  className={styles.category}
                  style={{ transitionDelay: `${index * 0.1}s` }}
                  activeClassName="active"
                >
                  {categoryText}
                </Link>
              </Fragment>
            );
          })}
        </div>
        <div className={styles.searchContainer} onClick={handleShowSearch} role="button" tabIndex={0}>
          <Search className={styles.searchIcon} />
        </div>
      </Section>
      <section className={`${styles.searchBarContainer} ${displaySearch ? styles.activeContainer : ''}`}>
        {displaySearch && (
          <SearchBar className="blog-nav-searchbar" active={displaySearch} closeSearch={handleShowSearch} />
        )}
      </section>
    </>
  );
};

export default CategoryNav;

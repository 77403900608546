// extracted by mini-css-extract-plugin
export var section = "styles-module--section--zR1Ye";
export var container = "styles-module--container--Z8mti";
export var title = "styles-module--title--FrU4S";
export var contactDetail = "styles-module--contactDetail--IXuRV";
export var contactSection = "styles-module--contactSection--zDDOR";
export var socials = "styles-module--socials--4Zcym";
export var socialIcon = "styles-module--socialIcon--kXbvf";
export var backAndForthRight = "styles-module--backAndForthRight--XQtda";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--6s0P5";
export var animateBg = "styles-module--animateBg--gggmn";
// extracted by mini-css-extract-plugin
export var section = "styles-module--section--wkI+O";
export var container = "styles-module--container--AKXEW";
export var category = "styles-module--category--TB7js";
export var title = "styles-module--title--fTXOK";
export var animateBg = "styles-module--animateBg--W7L8t";
export var authorDetails = "styles-module--authorDetails--kWnnv";
export var details = "styles-module--details--atuHk";
export var authorImage = "styles-module--authorImage--WzOAf";
export var author = "styles-module--author--jZWXF";
export var image = "styles-module--image--F4rkE";
export var backButton = "styles-module--backButton--RiSZD";
export var socials = "styles-module--socials--luXAI";
export var icon = "styles-module--icon--ogcFU";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--KdCta";
export var backAndForthRight = "styles-module--backAndForthRight--tSUlU";
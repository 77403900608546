import React from 'react';
import { PostCard } from 'components';
import * as styles from './styles.module.scss';

const FeaturedGrid = ({ posts, className }) => {
  const featuredPosts = posts?.slice(0, 5);
  return (
    <div className={`${className || ''} ${styles.postsContainer}`}>
      {featuredPosts.map((post, index) => {
        const { data, uid } = post;
        const isFirst = index === 0;
        return (
          <PostCard
            key={data?.title?.text}
            isFirst={isFirst}
            post={data}
            uid={uid}
            className={`${isFirst ? styles.firstCard : ''} ${styles.card}`}
          />
        );
      })}
    </div>
  );
};

export default FeaturedGrid;

import React from 'react';
import { Link } from 'components';
import generatePath from '../../helpers/generatePath';
import * as styles from './styles.module.scss';

const Breadcrumbs = ({ breadcrumbs, currentPage, centreAlign }) => {
  const schema = `
    <script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":[
          ${breadcrumbs?.map(
            (breadcrumb, index) => `{
              "@type": "ListItem",
              "position": ${index + 1},
              "item": {
                "@id": "${process.env.GATSBY_SITE_URL}/${breadcrumb?.breadcrumb_link?.url}",
                "name": "${breadcrumb?.breadcrumb_title?.text}"
              }
            }`
          )},
          {
            "@type": "ListItem",
            "position": ${breadcrumbs?.length + 1},
            "item": {
              "@id": "${process.env.GATSBY_SITE_URL}/${currentPage?.uid}",
              "name": "${currentPage?.title}"
            }
          }
        ]
      }
    </script>`;

  return (
    <section className={`${styles.breadcrumbs} ${centreAlign ? styles.center : ''}`}>
      {breadcrumbs?.map((breadcrumb) => (
        <div className={styles.item} key={breadcrumb?.breadcrumb_title?.text}>
          <Link to={`${process.env.GATSBY_SITE_URL}${breadcrumb?.breadcrumb_link?.url}`}>
            {breadcrumb?.breadcrumb_title?.text}
          </Link>
        </div>
      ))}
      <div className={`${styles.item} ${styles.current}`}>
        <span>{currentPage?.title}</span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: schema }} />
    </section>
  );
};

export default Breadcrumbs;

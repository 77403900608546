import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const Wysiwyg = (props) => {
  const { data, isPage } = props;
  const { primary } = data;
  const { wysiwyg } = primary;

  // content check
  const wysiwygHtml = wysiwyg?.html;
  const pageVariant = isPage ? styles.pageVariant : '';

  return (
    <Section noContainer={!isPage} className={`${pageVariant} ${styles.wysiwyg}`}>
      {wysiwygHtml && (
        <div
          className={styles.wysiwygContent}
          dangerouslySetInnerHTML={{
            __html: wysiwygHtml,
          }}
        />
      )}
    </Section>
  );
};

export default Wysiwyg;

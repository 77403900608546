import React, { useState, useEffect, Fragment } from 'react';
import { Navigation, Link, Logo, Hamburger } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { animate } from 'motion';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import * as styles from './styles.module.scss';

const Header = (props) => {
  // LINKS DATA FROM SETTINGS
  const headerQuery = graphql`
    query {
      ...HeaderLinks
    }
  `;

  const { location, transparent } = props;

  // PRISMIC SETTINGS DATA
  const { headerNavigationSettings } = useStaticQuery(headerQuery);
  const { data } = headerNavigationSettings;
  const {
    header_nav: headerNav,
    instagram_link: instagramLink,
    linkedin_link: linkedInLink,
    twitter_link: twitterLink,
  } = data;

  // STATE CONTROL
  const [active, setActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [shrinkOnScroll, setShrinkOnScroll] = useState(true);

  // SOCIAL LINKS
  const socialLinks = [
    { class: styles.twitter, link: twitterLink, alt: 'Twitter' },
    { class: styles.linkedin, link: linkedInLink, alt: 'LinkedIn' },
    { class: styles.instagram, link: instagramLink, alt: 'Instagram' },
  ];

  const topLinks = headerNav.filter((navItem) => !navItem.parent_link);
  const subLinks = headerNav.filter((navItem) => navItem.parent_link);

  const menuLinks = topLinks.map((navItem) => ({
    ...navItem,
    children: subLinks.filter((subLink) => subLink?.parent_link === navItem?.link_text?.text),
  }));

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      const hasScolled = currPos.y < -300;
      if (hasScolled && !scrolled) {
        setScrolled(true);
      }
			if (!hasScolled && scrolled) {
        setScrolled(false);
      }
      if (isShow !== shrinkOnScroll && currPos.y < -100) setShrinkOnScroll(isShow);
    },
    [shrinkOnScroll, scrolled]
  );

  // ANIMATIONS
  useEffect(() => {
    animate(
      '.pac-header-nav',
      {
        transform: shrinkOnScroll ? 'translateY(0px)' : 'translateY(-120px)',
        background: scrolled && shrinkOnScroll ? 'rgba(0,0,0,0.8)' : 'inherit',
      },
      { duration: 0.3, easing: 'ease-in' }
    );
  }, [shrinkOnScroll, scrolled]);

  const isFundsPage = location?.pathname?.includes('funds');
  const isHome = location?.pathname === '/';
  const isBlog = location?.pathname?.includes('insights');

  const blogClass = isBlog ? styles.blogPage : '';
  const fundsClass = isFundsPage ? styles.fundsPage : '';
  const homeClass = isHome ? styles.blackHeader : '';
  const transparentHeader = transparent ? styles.transparentHeader : '';

  return (
    <>
      <header
        className={`pac-header-nav ${styles.header} ${blogClass} ${fundsClass} ${homeClass} ${transparentHeader}`}
      >
        <div className={`container ${styles.container} ${styles.navExpand}`}>
          <Logo changeOnMobile />
        </div>
      </header>
    </>
  );
};

export default Header;

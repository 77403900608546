import React from 'react';
import { Section, Text, Link } from 'components';
import * as styles from './styles.module.scss';

const TwoColTitleText = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, title, tagline, cta_link: ctaLink, cta_text: ctaText, plain_cta: plainCta, theme } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  // THEME DEFINITION
  const themeStyles = {
    Black: 'black',
    'Off Black': 'offBlack',
    White: 'white',
    'Off White': 'offWhite',
  };

  return (
    <Section
      className={`${styles.section} ${themeStyles[theme] || 'black'}`}
      containerClassName={styles.container}
      sliceName="TwoColTitleText"
    >
      <div className={styles.grid}>
        <Text html={taglineHtml} h6 className={styles.tagline} />
      </div>
      <div className={styles.grid}>
        <div className={styles.left}>
          <Text html={titleHtml} h3 className={styles.title} />
          {hasCta && (
            <div>
              <Link
                to={ctaLink.url}
                className={plainCta ? 'cta gradient' : `button ${styles.button}`}
                withArrow={plainCta}
              >
                {ctaText.text}
              </Link>
            </div>
          )}
        </div>
        <div>
          <Text html={contentHtml} p2 className={styles.content} />
          {items.length > 0 && items[0]?.download_link?.url && (
            <div className={styles.downloadContainer}>
              {items.map((item) => {
                const { download_link: link, download_text: text } = item;
                return (
                  <Link to={link?.url} className={`cta gradient ${styles.downloadLink}`} withArrow>
                    {text?.text}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default TwoColTitleText;

// extracted by mini-css-extract-plugin
export var section = "styles-module--section--Vj-GR";
export var container = "styles-module--container--y7V3x";
export var leftContainer = "styles-module--leftContainer--Aw3+e";
export var rightContainer = "styles-module--rightContainer--LPm7L";
export var grid = "styles-module--grid--vP1B-";
export var link = "styles-module--link--JB2HQ";
export var title = "styles-module--title--WNYQA";
export var content = "styles-module--content--eutqV";
export var circles = "styles-module--circles--G114P";
export var circle = "styles-module--circle--tZmO4";
export var circleValue = "styles-module--circleValue--LQQDR";
export var circleProp = "styles-module--circleProp--Kq3xB";
export var circleContainer = "styles-module--circleContainer--FQTrF";
export var progressRing = "styles-module--progressRing--mwWQ4";
export var bgRing = "styles-module--bgRing--8WLWQ";
export var circleContent = "styles-module--circleContent--mo09c";
export var backAndForthRight = "styles-module--backAndForthRight----+ZN";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--FxRF2";
export var animateBg = "styles-module--animateBg--lUF92";
import React, { useState, useEffect, Fragment } from 'react';
import { Image, Text } from 'components';
import * as styles from './styles.module.scss';

const TypeScroll = ({ words, delay = 5000, speed = 2000, triggerPlay, onChange = () => {} }) => {
  // State control
  const [animationStep, setAnimationStep] = useState(0);

  useEffect(() => {
    onChange(0);
  }, []);

  // useEffect control
  // Initial useEffect to stagger
  useEffect(() => {
    let timeout = null;
    if (triggerPlay) {
      timeout = setTimeout(() => {
        setAnimationStep((step) => {
          const nextStep = step + 1;
          onChange(nextStep);
          return nextStep;
        });
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [triggerPlay]);

  // Rolling useEffect to continually animate
  useEffect(() => {
    let interval = null;
    if (triggerPlay) {
      interval = setInterval(() => {
        setAnimationStep((step) => {
          if (words.length - 1 === step) {
            onChange(0);
            return 0;
          }
          const nextStep = step + 1;
          onChange(nextStep);
          return nextStep;
        });
      }, speed);
      return () => {
        clearInterval(interval);
      };
    }
  }, [animationStep]);

  const getHideStep = (step) => {
    if (step === 0) {
      return words.length - 1;
    }
    return step - 1;
  };

  return (
    <div className={styles.scrollWheel}>
      {words.map((word, index) => (
        <Fragment key={word}>
          <p
            className={`
              ${styles.word}
              ${index === animationStep && words.length > 1 ? styles.animateIn : null}
              ${index === getHideStep(animationStep) && words.length > 1 ? styles.animateOut : null}
              ${
                index === animationStep || index === getHideStep(animationStep)
                  ? null
                  : words.length <= 1
                  ? null
                    : styles.animateKeepHidden
              }
              `}
          >
            <span>{word}</span>
          </p>
        </Fragment>
      ))}
    </div>
  );
};

export default TypeScroll;

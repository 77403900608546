import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { FormField } from 'components';
import * as styles from './styles.module.scss';

const FooterForm = ({ content, location, prefilledEmail }) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const { errors, dirtyFields } = formState;

  const fields = [
    {
      type: 'email',
      name: 'email',
      placeholder: 'Enter email',
      label: 'Email address',
      defaultValue: prefilledEmail || '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
  ];

  const onSubmit = async (values) => {
    navigate(`/subscribe?email=${values.email}`);
  };

  return (
    <section className={styles.newsletterForm}>
      <p className={styles.title}>Subscribe</p>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        {fields.map((field) => {
          const hasError = dirtyFields[field.name] && errors[field.name];
          return (
            <Fragment key={field.label || field.placeholder}>
              <div key={field.label} className={`${styles.field} ${field.className || ''}`}>
                {/* <span className={styles.label}>{field.label}</span> */}
                <FormField {...field} register={register} />
              </div>
              <div className={`${styles.fieldError} ${hasError ? styles.active : styles.inactive}`}>
                {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
              </div>
            </Fragment>
          );
        })}
        <button type="submit" aria-label="submit" className={`${styles.formButton} ${styles.arrow}`} />
      </form>
    </section>
  );
};

export default FooterForm;

import React, { useState } from 'react';
import { Section, Link, Image, OnVisible } from 'components';
import ReactPlayer from 'react-player';
import * as styles from './styles.module.scss';

const Video = (props) => {
  const { data, location } = props;
  const { primary } = data;
  const { theme, title, body_text: bodyText, video } = primary;

  const isBlogPost = location?.pathname?.includes('insights');
  // STATE CONTROL
  const [visible, setVisible] = useState(false);

  // EVENT HANDLERS
  const triggerVideo = (isVisible) => {
    setVisible(isVisible);
  };

  // THEME DEFINITION
  const themeStyles = {
    Black: 'black',
    'Off-Black': 'offBlack',
    White: 'white',
    'Off-White': 'offWhite',
    Blue: 'blue',
  };

  const config = isBlogPost
    ? { youtube: { playerVars: { controls: false, loop: false, autoplay: false, mute: false } } }
    : { youtube: { playerVars: { controls: false, loop: true, autoplay: false, mute: true } } };

  return (
    <Section
      className={`${themeStyles[theme] || 'black'} ${isBlogPost ? styles.blogVariant : ''}`}
      containerClassName={styles.container}
      sliceName="Video"
    >
      {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title?.html }} />}
      {bodyText && <div className={styles.text} dangerouslySetInnerHTML={{ __html: bodyText?.html }} />}
      {video?.url && (
        <OnVisible onChange={triggerVideo} threshold={0.5} triggerOnce>
          <div className={styles.videoContainer}>
            {visible && (
              <ReactPlayer
                className={styles.video}
                url={video?.url}
                playing={visible}
                playsinline
                loop
                config={config}
                width="100%"
                height="100%"
              />
            )}
          </div>
        </OnVisible>
      )}
    </Section>
  );
};

export default Video;

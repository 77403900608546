// extracted by mini-css-extract-plugin
export var section = "styles-module--section--dxpkY";
export var link = "styles-module--link--ePb9h";
export var image = "styles-module--image--dwZhz";
export var aspect21x9 = "styles-module--aspect21x9--a0EdW";
export var aspect16x9 = "styles-module--aspect16x9--m+eKw";
export var aspect4x3 = "styles-module--aspect4x3--6kdJO";
export var parallax = "styles-module--parallax--0CmR9";
export var backAndForthRight = "styles-module--backAndForthRight--HGFzi";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--606Sx";
export var animateBg = "styles-module--animateBg--2XOnJ";
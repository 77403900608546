import React from 'react';
import { ReactComponent as Arrow } from 'images/icons/icon-arrow-right.svg';
import { ReactComponent as ArrowGradient } from 'images/icons/icon-arrow-right-gradient.svg';
import { Link as GatsbyLink } from 'gatsby';
import * as styles from './styles.module.scss';

const Link = (props) => {
  const {
    children,
    activeClassName,
    className,
    to,
    title,
    target = '_blank',
    tabIndex,
    ariaLabel,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
    id,
    withArrow,
    arrow,
    style,
    arrowReverse,
    getProps = () => {},
  } = props;

  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');

  const arrows = {
    white: <Arrow className={`link-arrow ${styles.arrow}`} />,
    gradient: <ArrowGradient className={`link-arrow ${arrowReverse ? styles.arrowReverse : styles.arrow}`} />,
  };

  const containsSiteUrl = process.env.GATSBY_SITE_URL && to && to.indexOf(process.env.GATSBY_SITE_URL) !== -1;

  if (!to) {
    return (
      <span
        className={`${className || ''} ${styles.link} ${withArrow ? styles.withArrow : ''}  ${
          arrow === 'gradient' ? styles.gradient : ''
        }`}
        style={style}
      >
        {arrow && arrowReverse && arrows[arrow]}
        {children}
        {withArrow && !arrowReverse && <Arrow className={`link-arrow ${styles.arrow}`} />}
        {arrow && !arrowReverse && arrows[arrow]}
      </span>
    );
  }

  if (isExternal && !containsSiteUrl) {
    return (
      <a
        href={to}
        className={`${className || ''} ${styles.link} ${withArrow ? styles.withArrow : ''} ${
          arrow ? styles.withArrow : ''
        }  ${arrow === 'gradient' ? styles.gradient : ''}`}
        title={title || null}
        aria-label={ariaLabel || `link to external site: ${to}`}
        target={target}
        onClick={onClick}
        id={id || ''}
        rel="nofollow noopener noreferrer"
        style={style}
      >
        {arrow && arrowReverse && arrows[arrow]}
        {arrow === 'gradient' ? <span>{children}</span> : children}
        {withArrow && !arrowReverse && <Arrow className={`link-arrow ${styles.arrow}`} />}
        {arrow && !arrowReverse && arrows[arrow]}
      </a>
    );
  }

  const withTrailingSlash = `${to}${to.endsWith('/') ? '' : '/'}`;
  const linkTo = containsSiteUrl ? withTrailingSlash.replace(containsSiteUrl, '') : withTrailingSlash;

  return (
    <GatsbyLink
      to={linkTo}
      aria-label={ariaLabel || `link to page ${linkTo}`}
      className={`${className || ''} ${styles.link || ''} ${withArrow ? styles.withArrow : ''} ${
        arrow ? styles.withArrow : ''
      } ${arrow === 'gradient' ? styles.gradient : ''}`}
      activeClassName={activeClassName}
      title={title || null}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      tabIndex={tabIndex}
      id={id}
      style={style}
      getProps={getProps}
    >
      {arrow && arrowReverse && arrows[arrow]}
      {arrow === 'gradient' ? <span>{children}</span> : children}
      {withArrow && !arrowReverse && <Arrow className={`link-arrow ${styles.arrow}`} />}
      {arrow && !arrowReverse && arrows[arrow]}
    </GatsbyLink>
  );
};

export default Link;

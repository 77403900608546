import React from 'react';
import { Image } from 'components';
import './styles.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const { className, containerClassName, children, sliceName, noContainer, containerStyles, bgImage, largeContainer } =
    props;
  return (
    <section
      className={`section ${className || ''} ${largeContainer ? 'large-container' : ''}`}
      style={containerStyles && containerStyles}
      data-slice={isDev ? sliceName : null}
    >
      {bgImage && <Image image={bgImage} className="section-bg" />}
      {noContainer ? children : <div className={`container ${containerClassName || ''}`}>{children}</div>}
    </section>
  );
};

export default Section;

const generatePath = require('../config/generatePath');

module.exports = (doc) => {
  const { uid } = doc;
  // Example of link resolver for other post type
  // --------------------------------------------
  if (doc.type === 'blog_post' || doc.type === 'category') {
    return `/insights/${uid}/`;
  }

  return generatePath(uid);
 }

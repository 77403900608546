const classNames = (...args) => {
  const classes = args.reduce((classesArray, arg) => {
    const argType = typeof arg;

    // If null return
    if (arg === null) return classesArray;

    // String or Number
    if (argType === 'string' || argType === 'number') {
      classesArray.push(arg);
    }

    // Object
    if (argType === 'object') {
      Object.entries(arg).forEach(([key, value]) => {
        if (value) classesArray.push(key);
      });
    }

    return classesArray;
  }, []);

  if (classes.length > 0) return classes.join(' ');
  return '';
};

export default classNames;

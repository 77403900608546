// extracted by mini-css-extract-plugin
export var words = "styles-module--words--FFWl6";
export var word = "styles-module--word--QQqZf";
export var scrollWheel = "styles-module--scrollWheel--L0bW4";
export var animateBg = "styles-module--animateBg--5xwY-";
export var animateIn = "styles-module--animateIn--JNhh-";
export var animateScrollIn = "styles-module--animateScrollIn--mHlCu";
export var animateOut = "styles-module--animateOut--VEQyK";
export var animateScrollOut = "styles-module--animateScrollOut--1KvmD";
export var animateSingleLogo = "styles-module--animateSingleLogo--qAl8S";
export var animateKeepHidden = "styles-module--animateKeepHidden--6Oq5E";
export var backAndForthRight = "styles-module--backAndForthRight--HKykO";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--bOVpw";
// extracted by mini-css-extract-plugin
export var section = "styles-module--section--+S79C";
export var container = "styles-module--container--Ojt0v";
export var step = "styles-module--step--W8Y2-";
export var title = "styles-module--title--Iv60o";
export var contactDetail = "styles-module--contactDetail--kW9FH";
export var addressTitle = "styles-module--addressTitle--wuREM";
export var address = "styles-module--address--WsKIh";
export var logoContainer = "styles-module--logoContainer--VoT4Z";
export var backAndForthRight = "styles-module--backAndForthRight--AAw5v";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--oTv1Y";
export var animateBg = "styles-module--animateBg--rSfkG";
// extracted by mini-css-extract-plugin
export var section = "styles-module--section--q-qJt";
export var container = "styles-module--container--WOPwp";
export var left = "styles-module--left--6zugh";
export var nameContainer = "styles-module--nameContainer--Ou42R";
export var name = "styles-module--name---FBZz";
export var headline = "styles-module--headline--Q6tQC";
export var bio = "styles-module--bio--0S3BW";
export var titleContainer = "styles-module--titleContainer--dckj4";
export var logo = "styles-module--logo--rJ58d";
export var right = "styles-module--right--5UiBi";
export var quote = "styles-module--quote--Nar+J";
export var buttonContainer = "styles-module--buttonContainer--14qtJ";
export var email = "styles-module--email--B0xDp";
export var linkedin = "styles-module--linkedin--CLKyF";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--7LtVc";
export var backAndForthRight = "styles-module--backAndForthRight--3vi+t";
export var animateBg = "styles-module--animateBg--Azz72";
import React from 'react';
import { Section, Link, Image, Text } from 'components';
import * as styles from './styles.module.scss';

const TitleLargeImageCta = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, image, cta_text: ctaText, cta_link: ctaLink, theme } = primary;

  const titleHtml = title?.html;
  const hasCta = ctaLink?.url && ctaText?.text;
  const hasImage = image?.url;

  // THEME DEFINITION
  const themeStyles = {
    Black: 'black',
    'Off-Black': 'offBlack',
    White: 'white',
    'Off-White': 'offWhite',
  };

  return (
    <Section
      className={`${styles.section} ${themeStyles[theme] || 'white'}`}
      containerClassName={styles.container}
      sliceName="TitleLargeImageCta"
    >
      <Text html={titleHtml} h3 className={styles.title} />
      {hasImage && <Image image={image} className={styles.image} />}
      {hasCta && (
        <Link to={ctaLink?.url} className={`${styles.cta}`} arrow="gradient">
          {ctaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default TitleLargeImageCta;

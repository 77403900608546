import React from 'react';
import { Footer, Header, SEO, NoJs, WmAscii, Cursor } from 'components';
import 'typeface-lato';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, seo, location, transparentHeader } = props;

  return (
    <>
      <NoJs />
      <WmAscii />
      {/* <Cursor /> */}
      <Header location={location} transparent={transparentHeader} />
      <main className={transparentHeader ? 'has-transparent-header' : ''}>
        {!customSEO && <SEO {...seo} location={location} />}
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;

import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const SimpleQuote = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content } = primary;

  const quoteText = content?.text;

  return (
    <Section className={styles.simpleQuote} noContainer>
      <blockquote className={styles.simpleQuoteText}>{quoteText}</blockquote>
    </Section>
  );
};

export default SimpleQuote;

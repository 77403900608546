import React, { useState, useEffect } from 'react';
import { Section, Link, Text, OnVisible } from 'components';
import CountUp from 'react-countup';
import * as styles from './styles.module.scss';

const TwoColsTextPercentageCircles = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    title,
    link_text: linkText,
    link,
    content,
    circle_1_value: circle1Val,
    circle_1_prefix: circle1Prefix,
    circle_1_suffix: circle1Suffix,
    circle_1_content: circle1Content,
    circle_2_value: circle2Val,
    circle_2_prefix: circle2Prefix,
    circle_2_suffix: circle2Suffix,
    circle_2_content: circle2Content,
  } = primary;

  // STATE CONTROL
  const [visibleC1, setVisibleC1] = useState(false);
  const [visibleC2, setVisibleC2] = useState(false);
  const [width, setWidth] = useState(null);
  const [breakPoint, setBreakPoint] = useState(null);
  const [activeRadius, setActiveRadius] = useState(0);
  // FUNCTIONS
  // visibility sensor
  const triggerC1 = (isVisible) => {
    setVisibleC1(isVisible);
  };

  const triggerC2 = (isVisible) => {
    setVisibleC2(isVisible);
  };

  // WINDOW LISTENER
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  // SET BREAKPOINT STATE
  useEffect(() => {
    const widthRef = width || window.innerWidth;
    const breakpoints = {
      desktop: 1400,
      largeTab: 1025,
      medTab: 900,
      tab: 769,
      mob: 400,
    };
    if (widthRef >= 1400) {
      setBreakPoint('desktop');
    }
    for (const [key, value] of Object.entries(breakpoints)) {
      if (widthRef <= value) {
        setBreakPoint(key);
      }
    }
  }, [width]);

  useEffect(() => {
    // change svg radius/size depending on screen
    const breakpointRadius = {
      desktop: 150,
      largeTab: 150,
      medTab: 150,
      tab: 150,
      mob: 150,
    };
    setActiveRadius(breakpointRadius[breakPoint]);
  }, [breakPoint]);

  // // change svg radius/size depending on screen
  // const breakpointRadius = {
  //   desktop: 150,
  //   largeTab: 150,
  //   medTab: 150,
  //   tab: 150,
  //   mob: 150,
  // };
  // const activeRadius = breakpointRadius[breakPoint] || '';

  return (
    <Section
      className={`percentage-circles ${styles.section}`}
      containerClassName={styles.container}
      sliceName="TwoColsTextPercentageCircles"
    >
      <div className={styles.leftContainer}>
        {title?.html && <Text html={title?.html} h3 className={styles.title} />}
        {content?.html && <Text html={content?.html} p3 className={styles.content} />}
        {link?.url && (
          <Link to={link?.url} className={styles.link} arrow="gradient">
            {linkText?.text !== '' ? linkText?.text : 'Explore'}
          </Link>
        )}
      </div>
      <div className={styles.rightContainer}>
        {/* PERCENTAGE CIRCLES */}
        <div className={styles.circles}>
          <OnVisible
            className={styles.circleContainer}
            visibleClassName={styles.visibleCircles}
            onChange={triggerC1}
            threshold={1}
            delay={600}
          >
            {/* COLOURED RING */}
            <ProgressRing
              radius={activeRadius}
              stroke={13}
              // 62% of 90
              progress={visibleC1 ? 97.8 : 0}
              className={styles.progressRing}
            />
            {/* BG RING */}
            <ProgressRing radius={activeRadius} stroke={13} progress={100} className={styles.progressRing} isBg />
            {circle1Val && (
              <div className={styles.circle}>
                <CountUp
                  className={styles.circleValue}
                  duration={1.5}
                  end={visibleC1 ? circle1Val : 0}
                  prefix={circle1Prefix?.text || ''}
                  suffix={circle1Suffix?.text || ''}
                />
              </div>
            )}
          </OnVisible>
        </div>
        {circle1Content?.html && <Text html={circle1Content?.html} className={styles.circleContent} />}
        <div className={styles.circles}>
          <OnVisible
            className={styles.circleContainer}
            visibleClassName={styles.visibleCircles}
            onChange={triggerC2}
            threshold={0.6}
            delay={1000}
          >
            {/* COLOURED RING */}
            <ProgressRing
              radius={activeRadius}
              stroke={13}
              className={styles.progressRing}
              progress={visibleC2 ? 97.8 : 0}
            />
            {/* BG RING */}
            <ProgressRing radius={activeRadius} stroke={13} progress={100} className={styles.progressRing} isBg />
            {circle2Val && (
              <div className={styles.circle}>
                <CountUp
                  className={styles.circleValue}
                  duration={1.5}
                  end={visibleC2 ? circle2Val : 0}
                  prefix={circle2Prefix?.text || ''}
                  suffix={circle2Suffix?.text || ''}
                />
              </div>
            )}
          </OnVisible>
        </div>
        {circle2Content?.html && <Text html={circle2Content?.html} className={styles.circleContent} />}
      </div>
    </Section>
  );
};

export const ProgressRing = ({ radius, stroke, progress, className, isBg }) => {
  const normalizedRadius = radius ? radius - stroke * 2 : 0;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <div className={`${className || ''} ${isBg ? styles.bgRing : ''}`}>
      <svg height={radius ? radius * 2 : 0} width={radius ? radius * 2 : 0}>
        <defs>
          <linearGradient id="linear" x1="50%" y1="0%" x2="10%" y2="100%" gradientTransform="rotate(-15)">
            <stop offset="0%" stopColor="#FF00B8" />
            <stop offset="20%" stopColor="#EB2CFC" />
            <stop offset="70%" stopColor="#2C41F8" />
            <stop offset="100%" stopColor="#1F9CF6" />
          </linearGradient>
        </defs>
        <circle
          stroke={isBg ? '#282828' : 'url(#linear)'}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius || 0}
          cx={radius || 0}
          cy={radius || 0}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default TwoColsTextPercentageCircles;

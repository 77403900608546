import React, { Fragment } from 'react';
import { Section, Link, Text } from 'components';
import * as styles from './styles.module.scss';

const FeatureCarousel = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="FeatureCarousel" noContainer>
      <Text html={title.html} h5 className={styles.title} />
      <div className={styles.carouselContainer}>
        <div className={styles.spacerTile} />
        {items?.map((card, index) => {
          const {
            card_title: cardTitle,
            card_content: cardContent,
            card_link: cardLink,
            card_link_title: cardLinkTitle = 'Discover',
          } = card;
          return (
            <Fragment key={cardTitle.text}>
              <Link to={cardLink.url}>
                <div className={styles.gradientBg}>
                  <div
                    className={`${styles.cardContainer} ${index === items?.length - 1 && styles.finalTile}`}
                    key={cardTitle}
                  >
                    <Text html={cardTitle.html} h4 className={styles.cardTitle} />
                    {cardContent.html ? <Text html={cardContent.html} p2 className={styles.cardContent} /> : <span />}
                    <Link to={cardLink.url} className={`cta ${styles.cardLink}`} withArrow>
                      {cardLinkTitle?.text !== '' ? cardLinkTitle?.text : 'Discover'}
                    </Link>
                  </div>
                </div>
              </Link>
            </Fragment>
          );
        })}
      </div>
    </Section>
  );
};

export default FeatureCarousel;

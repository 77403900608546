// extracted by mini-css-extract-plugin
export var section = "styles-module--section--eSoha";
export var container = "styles-module--container--+V9Yn";
export var carouselContainer = "styles-module--carouselContainer--xI4Iq";
export var gradientBg = "styles-module--gradientBg--1zCY5";
export var animateBg = "styles-module--animateBg--uHkbW";
export var cardContainer = "styles-module--cardContainer--+EoLP";
export var spacerTile = "styles-module--spacerTile--bHJLS";
export var finalTile = "styles-module--finalTile--VS1Sz";
export var title = "styles-module--title--CdQTo";
export var cardTitle = "styles-module--cardTitle--2sATX";
export var cardContent = "styles-module--cardContent--j6-4m";
export var backAndForthRight = "styles-module--backAndForthRight--CkY+C";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--dQ8nl";
import React from 'react';
import { Image, Section, Link } from 'components';
import * as styles from './styles.module.scss';

const ImageSlice = (props) => {
  const { data } = props;
  const { primary } = data;
  const { image, image_link: imageLink } = primary;

  return (
    <Section className={styles.imageSlice} noContainer>
      {imageLink?.url ? (
        <Link to={imageLink.url}>
          <Image image={image} />
        </Link>
      ) : (
        <Image image={image} />
      )}
    </Section>
  );
};

export default ImageSlice;

import React, { useState } from 'react';
import { NewsletterSignup, Link, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const BlogPopularCta = ({ posts, cta }) => (
  <OnVisible className={styles.container} visibleClassName={styles.visibleContainer} threshold={0.3}>
    <div className={styles.postsContainer}>
      <span className={styles.title}>Most popular</span>
      {/* posts */}
      <div className={styles.cards}>
        {posts.map((post, index) => {
          const {
            uid: postLink,
            data: {
              author: {
                document: {
                  data: { author_name: author },
                },
              },
              category: {
                document: {
                  uid: categoryLink,
                  data: { category_name: category },
                },
              },
              date,
              title,
            },
          } = post;

          return (
            <div className={styles.card} id="card" key={title.text} style={{ transitionDelay: `${index * 0.1}s` }}>
              <Link to={categoryLink}>
                {category?.text && <span className={styles.category}>{category.text}</span>}
              </Link>
              <Link to={postLink} className={styles.titleLink}>
                {title?.text && <span className={styles.cardTitle}>{title.text}</span>}
              </Link>
              <div className={styles.details}>
                {date && <span className={styles.date}>{date}</span>}
                {author?.text && date && <span className={styles.dot}>•</span>}
                {author?.text && <span className={styles.author}>{author.text}</span>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
    <NewsletterSignup cta={cta} />
  </OnVisible>
);

export default BlogPopularCta;

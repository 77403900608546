// extracted by mini-css-extract-plugin
export var postContent = "blogpost-module--postContent--0-7yK";
export var contentContainer = "blogpost-module--contentContainer--n6-VQ";
export var longForm = "blogpost-module--longForm--MIHdg";
export var sidebarContainer = "blogpost-module--sidebarContainer--z9DLy";
export var sidebar = "blogpost-module--sidebar--g7pZ6";
export var sliceContainer = "blogpost-module--sliceContainer--hCinL";
export var postContainer = "blogpost-module--postContainer--YgM3y";
export var backAndForthRight = "blogpost-module--backAndForthRight--gFNSL";
export var wobbleRightAndLeft = "blogpost-module--wobbleRightAndLeft--i1V4A";
export var animateBg = "blogpost-module--animateBg--ez8si";
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Link, Image, Text } from 'components';
import * as styles from './styles.module.scss';

const FooterCta = () => {
  const data = useStaticQuery(graphql`
    query settingsData {
      prismicSettings {
        data {
          footer_cta_link {
            url
          }
          footer_cta_link_text {
            text
          }
          footer_cta_text {
            html
            text
          }
        }
      }
    }
  `);

  const {
    prismicSettings: {
      data: { footer_cta_link: ctaLink, footer_cta_link_text: ctaText, footer_cta_text: title },
    },
  } = data;

  return (
    <Section className={`${styles.section} footer-cta`} containerClassName={styles.container} sliceName="FooterCta">
      <Text html={title?.html} h3 className={styles.title} />
      {ctaLink?.url && (
        <Link to={ctaLink?.url} className={`cta ${styles.cta}`} withArrow>
          {ctaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default FooterCta;

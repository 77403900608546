import React from 'react';
import { Image, Link } from 'components';
import * as styles from './styles.module.scss';

const PostCard = ({ post, className, isFirst, uid, vertical }) => {
  const {
    author: { document: authorDocument = {} },
    category: { document: categoryDocument = {} },
    date,
    title,
    featured_image: image,
    body,
  } = post;

  const author = authorDocument?.data?.author_name;
  const categoryLink = categoryDocument?.uid;
  const category = categoryDocument?.data?.category_name;

  const veritcalVariant = vertical ? styles.verticalVariant : '';
  const text = body?.find((item) => item?.id);
  const excerpt = text?.primary?.wysiwyg?.text;

  return (
    <div className={`${className || ''} ${veritcalVariant} ${styles.card} ${isFirst ? styles.largeImage : ''}`}>
      {image?.url && (
        <Link to={`/insights/${uid}`} className={styles.imageLink}>
          <Image image={image} className={styles.image} objectFit="COVER" />
        </Link>
      )}
      <div className={styles.textContainer}>
        {category?.text && (
          <Link to={`/insights/${categoryLink}`} className={styles.category}>
            <span>{category.text}</span>
          </Link>
        )}
        {title?.text && (
          <Link to={`/insights/${uid}`} className={styles.title}>
            <span>{title.text}</span>
          </Link>
        )}
        <div className={styles.details}>
          {date && <span className={styles.date}>{date}</span>}
          {author?.text && date && <span className={styles.dot}>•</span>}
          {author?.text && <span className={styles.author}>{author.text}</span>}
        </div>
        {vertical && <span className={styles.excerpt}>{excerpt}</span>}
      </div>
    </div>
  );
};

export default PostCard;

import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import {
  Layout,
  Slice,
  BlogPostHeader,
  NewsletterSignup,
  Section,
  TableOfContents,
  PostGrid,
  FooterCta,
} from 'components';
import * as styles from './blogpost.module.scss';

const BlogPost = ({ location, data, pageContext }) => {
  const { prismicBlogPost, prismicSettings, allPrismicBlogPost } = data;

  const ctaData = prismicSettings?.data;
  const relatedPosts = allPrismicBlogPost?.nodes;

  const {
    data: pageData,
    dataRaw,
    first_publication_date: publishedDate,
    last_publication_date: updatedDate,
  } = prismicBlogPost;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    author,
    category,
    date,
    format: longPost,
    title,
    featured_image: image,
  } = pageData;

  const seo = {
    title: metaTitle?.text || title?.text,
    description: metaDesc?.text,
    ogImage: ogImage?.url,
    article: true,
    publishedDate,
    updatedDate,
  };

  const dataString = JSON.stringify(dataRaw);

  const postDetails = {
    author,
    category,
    date,
    title,
    dataString,
    image,
  };

  // collect anchor titles from body
  const chapterTitleData = sliceData.filter((slice) => slice.slice_type === 'anchor_title');
  const anchorTitles = [];
  chapterTitleData.forEach((item) => {
    const { primary } = item;
    const { title1: chapterTitle } = primary;
    const { text: titleText } = chapterTitle;
    anchorTitles.push(titleText);
  });

  const categoryUid = category?.document?.uid;

  return (
    <Layout location={location} seo={seo}>
      <BlogPostHeader {...postDetails} />
      <Section
        className={`${longPost ? styles.longForm : ''} ${styles.postContent}`}
        containerClassName={styles.contentContainer}
      >
        <div className={styles.sliceContainer}>
          {sliceData.map((slice) => (
            <Slice key={slice.id} data={slice} location={location} />
          ))}
        </div>
        <div className={styles.sidebarContainer}>
          {longPost ? (
            <TableOfContents anchorTitles={anchorTitles} className={styles.sidebar} />
          ) : (
            <NewsletterSignup darkBg cta={ctaData} location={location} className={styles.sidebar} isBlogPost />
          )}
        </div>
      </Section>
      {categoryUid && relatedPosts && relatedPosts.length > 0 && (
        <Section className={styles.postContainer}>
          <PostGrid
            posts={relatedPosts}
            title="Related posts"
            categoryLink={categoryUid}
            categoryLinkText="Read more posts"
          />
        </Section>
      )}
      <FooterCta />
    </Layout>
  );
};

export default withPrismicPreview(BlogPost);

export const blogPostQuery = graphql`
  query BlogPostBySlug($uid: String, $categoryUid: String) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      uid
      _previewable
      prismicId
      first_publication_date
      last_publication_date
      dataRaw
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                instagram {
                  url
                }
                linkedin {
                  url
                }
                twitter {
                  url
                }
                author_image {
                  url
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
                    }
                  }
                }
                author_name {
                  html
                  text
                }
              }
            }
          }
        }
        category {
          document {
            ... on PrismicCategory {
              id
              data {
                category_name {
                  html
                  text
                }
              }
              uid
            }
          }
        }
        date(formatString: "MMMM D, YYYY")
        format
        title {
          html
          text
        }
        featured_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
            }
          }
        }
        body {
          ...AnchorTitleBlogPost
          ...BlogPostImage
          ...BlogPostSimpleQuote
          ...BlogPostWysiwyg
          ...VideoBlogPostFragment
        }
      }
    }
    prismicSettings {
      data {
        sign_up_content {
          html
          text
        }
        sign_up_title {
          html
          text
        }
      }
    }
    allPrismicBlogPost(
      limit: 6
      sort: { fields: data___date, order: DESC }
      filter: { data: { category: { uid: { eq: $categoryUid } } }, uid: { ne: $uid } }
    ) {
      nodes {
        uid
        data {
          author {
            url
            document {
              ... on PrismicAuthor {
                id
                data {
                  author_image {
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
                      }
                    }
                  }
                  author_name {
                    text
                    html
                  }
                }
              }
            }
          }
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  category_name {
                    html
                    text
                  }
                }
                uid
              }
            }
          }
          featured_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          format
          is_popular
          title {
            html
            text
          }
          date(formatString: "MMMM D, YYYY")
        }
      }
    }
    allPrismicCategory {
      nodes {
        data {
          category_name {
            html
            text
          }
        }
        uid
      }
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { Section, Link, Image, Text } from 'components';
import * as styles from './styles.module.scss';

const VerticalScroller = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, tagline, text_main: textMain, cta_link: ctaLink, cta_text: ctaText } = primary;
  const taglineHtml = tagline?.html;
  const titleHtml = title?.html;
  const contentMain = textMain?.html;
  const link = ctaLink?.url;
  const linkText = ctaText?.html;

  // CALCULATE THE ITEM TITLE CHANGES ON SCROLL
  const [windowHeight, setWindowHeight] = useState(null);
  const [active, setActive] = useState([]);

  const generateId = (text) => (text ? text.replace(/\s+/g, '-').toLowerCase() : '');

  const elementIds = [];

  items.forEach((item, index) => {
    elementIds.push(item?.step_title?.text);
  });

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => setWindowHeight(window.innerHeight));
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('resize', () => setWindowHeight(window.innerHeight));
    };
  }, [windowHeight]);

  // scroll listener
  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener('scroll', getElementOffsets);
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('scroll', getElementOffsets);
    };
  }, [active, windowHeight]);

  const getElementOffsets = () => {
    const is50Percent = windowHeight / 1.8;
    const activeElements = [...active];
    elementIds.forEach((id) => {
      const element = document?.getElementById(id);
      const offset = element.getBoundingClientRect().top;
      if (offset <= is50Percent && !activeElements.includes(id)) {
        activeElements.push(id);
        element.className += ' active';
        setActive(activeElements);
      }
      if (offset > is50Percent && activeElements.includes(id)) {
        const index = activeElements.indexOf(id);
        activeElements.splice(index, 1);
        element.classList.remove('active');
        setActive(activeElements);
      }
    });
  };

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="VerticalScroller">
      <div className={styles.leftContainer}>
        <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />
        <Text html={titleHtml} h3 className={styles.title} />
        <Text html={contentMain} p4 className={styles.content} />{' '}
        {ctaLink?.url && (
          <Link to={link} className={styles.link} arrow="gradient">
            {linkText !== '' ? <Text html={linkText} h6 className={styles.link} /> : 'Explore'}
          </Link>
        )}
      </div>
      <div className={styles.rightContainer}>
        {items?.length > 0 &&
          items?.map((item, index) => {
            const itemContent = item?.step_content?.html;
            const itemTitle = item?.step_title?.html;
            const itemLink = item?.link?.url;
            const itemLinkTitle = item?.link_title?.html;
            const itemIcon = item?.icon?.url;

            return (
              <div id={item?.step_title?.text} key={index} className={styles.itemContainer}>
                {itemIcon ? (
                  <Image className={styles.itemIcon} image={item?.icon} />
                ) : (
                  <h4 className={styles.itemIndex}>{index + 1}</h4>
                )}
                <div className={styles.itemContentContainer}>
                  {itemLink ? (
                    <Link to={itemLink} className={styles.linkScroller}>
                      <Text
                        html={itemTitle}
                        h4
                        className={`${styles.itemTitle} ${itemIcon && styles.itemTitleIcons}`}
                      />
                    </Link>
                  ) : (
                    <Text html={itemTitle} h4 className={`${styles.itemTitle} ${itemIcon && styles.itemTitleIcons}`} />
                  )}
                  <Text html={itemContent} p4 className={itemLink ? '' : styles.itemContent} />
                  {itemLink && (
                    <Link to={itemLink} className={styles.linkScroller} arrow="gradient">
                      {itemLinkTitle !== '' ? <Text html={itemLinkTitle} p3 className={styles.itemLink} /> : 'Explore'}
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </Section>
  );
};

export default VerticalScroller;

// extracted by mini-css-extract-plugin
export var container = "styles-module--container--pvUEx";
export var iconContainer = "styles-module--iconContainer--2mPFo";
export var title = "styles-module--title--myLZb";
export var content = "styles-module--content--pQKDf";
export var form = "styles-module--form--6vuRq";
export var label = "styles-module--label--bdLbf";
export var submitButton = "styles-module--submitButton--oIz8S";
export var visibleContainer = "styles-module--visibleContainer--SIMY-";
export var darkBg = "styles-module--darkBg--G3wVH";
export var icon = "styles-module--icon--1U3fx";
export var socials = "styles-module--socials--VBAGb";
export var iconsContainer = "styles-module--iconsContainer--f7iL+";
export var shareText = "styles-module--shareText--cDuIb";
export var backAndForthRight = "styles-module--backAndForthRight--loFPz";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--2QCXM";
export var animateBg = "styles-module--animateBg--qpSgL";
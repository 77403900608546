import React, { useState, useEffect, Fragment } from 'react';
import { PostCard, Link } from 'components';
import * as styles from './styles.module.scss';

const PostGrid = ({ posts = [], className, title, categoryLink, tagline, categoryLinkText, vertical }) => {
  const largeTitle = tagline ? styles.largeTitle : '';

  return (
    <div className={className || ''}>
      {title && (
        <div className={styles.titleContainer}>
          <div>
            <span className={`${largeTitle} ${styles.title}`}>{title}</span>
            {tagline && <span className={styles.tagline}>{tagline}</span>}
          </div>
          {categoryLink && (
            <Link to={categoryLink} className={`cta ${styles.cta}`} withArrow>
              {categoryLinkText || 'See more'}
            </Link>
          )}
        </div>
      )}
      <div className={` ${vertical ? styles.verticalVariant : ''} ${styles.postsContainer}`}>
        {posts.map((post) => {
          const { data = {}, uid } = post;
          return (
            <Fragment key={uid}>
              <PostCard post={data} uid={uid} vertical={vertical} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PostGrid;

// extracted by mini-css-extract-plugin
export var header = "styles-module--header--a+NL6";
export var navShrink = "styles-module--navShrink--HAWIG";
export var navExpand = "styles-module--navExpand--FlK-m";
export var fundsPage = "styles-module--fundsPage--fc3R7";
export var fundsParent = "styles-module--fundsParent--Mjqw0";
export var animateBg = "styles-module--animateBg--Uqpy+";
export var blogPage = "styles-module--blogPage--T1W2Z";
export var insightsLink = "styles-module--insightsLink--Bp76-";
export var blackHeader = "styles-module--blackHeader--Q0QVa";
export var transparentHeader = "styles-module--transparentHeader--5aP4M";
export var container = "styles-module--container--vzrwR";
export var containerDesktopNav = "styles-module--containerDesktopNav--7c5t+";
export var link = "styles-module--link--PYJZu";
export var chevron = "styles-module--chevron--art3T";
export var button = "styles-module--button--YTzv7";
export var containerSublinks = "styles-module--containerSublinks--17Rf1";
export var sublink = "styles-module--sublink--A-xfP";
export var linkContainer = "styles-module--linkContainer--PPRCN";
export var nav = "styles-module--nav--EHi7K";
export var twitter = "styles-module--twitter--vfiEG";
export var instagram = "styles-module--instagram--RSI2x";
export var linkedin = "styles-module--linkedin--tp6OT";
export var backAndForthRight = "styles-module--backAndForthRight--o-vYl";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--KZRyi";
import React from 'react';
import { Section, Link, Image, Text } from 'components';
import * as styles from './styles.module.scss';

const TwoColTextImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    image_left: imageLeft,
    tagline,
    title,
    theme,
  } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasImage = image?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  // THEME DEFINITION
  const themeStyles = {
    Black: 'black',
    'Off-Black': 'offBlack',
    White: 'white',
    'Off-White': 'offWhite',
  };

  return (
    <Section
      className={`two-col-image-text ${imageLeft ? styles.imageLeft : ''} ${themeStyles[theme] || 'white'} ${
        styles.section
      }`}
      containerClassName={styles.container}
      sliceName="TwoColTextImage"
    >
      <div className={styles.grid}>
        <div className={styles.text}>
          <Text html={taglineHtml} h6 className={styles.tagline} />
          <Text html={titleHtml} h4 className={styles.title} />
          <Text html={contentHtml} p2 className={styles.content} />{' '}
          {hasCta && (
            <Link className={`cta gradient ${styles.cta}`} to={ctaLink.url} arrow="gradient">
              {ctaText.text}
            </Link>
          )}
        </div>
        {hasImage && <Image className={styles.image} image={image} />}
      </div>
    </Section>
  );
};

export default TwoColTextImage;

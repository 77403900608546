// extracted by mini-css-extract-plugin
export var container = "styles-module--container--eGJGj";
export var postsContainer = "styles-module--postsContainer--xFnSO";
export var titleLink = "styles-module--titleLink--30z89";
export var title = "styles-module--title--4Ccl3";
export var cards = "styles-module--cards--m1LWw";
export var card = "styles-module--card--ZBxLJ";
export var category = "styles-module--category--xzuId";
export var animateBg = "styles-module--animateBg--SX8lN";
export var cardTitle = "styles-module--cardTitle--oFgbp";
export var details = "styles-module--details--vkyyH";
export var dot = "styles-module--dot--9bEMb";
export var visibleContainer = "styles-module--visibleContainer--ZNDqX";
export var backAndForthRight = "styles-module--backAndForthRight--+qL30";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--UChUT";
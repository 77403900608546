// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--ohZ-9";
export var container = "styles-module--container--jqnpZ";
export var subContainer = "styles-module--subContainer--BCnAL";
export var logo = "styles-module--logo--vm1Bk";
export var title = "styles-module--title--E98Kx";
export var sitemap = "styles-module--sitemap--x8773";
export var socialLinksContainer = "styles-module--socialLinksContainer--dDC6+";
export var socialIcon = "styles-module--socialIcon--TkCKi";
export var newsletter = "styles-module--newsletter--Fqct7";
export var twitter = "styles-module--twitter--hMavj";
export var instagram = "styles-module--instagram--rB8a4";
export var linkedin = "styles-module--linkedin--X6qhX";
export var facebook = "styles-module--facebook--PCipP";
export var youtube = "styles-module--youtube--6Q7wZ";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--4ThBa";
export var backAndForthRight = "styles-module--backAndForthRight--J2-2v";
export var animateBg = "styles-module--animateBg--hpaa2";
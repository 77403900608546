// extracted by mini-css-extract-plugin
export var section = "styles-module--section--9OewV";
export var grid = "styles-module--grid--SYFKQ";
export var title = "styles-module--title--swRjr";
export var iconContainer = "styles-module--iconContainer--Pohi5";
export var iconTitle = "styles-module--iconTitle--Gz4dO";
export var icon = "styles-module--icon--Rwu3F";
export var visibleContainer = "styles-module--visibleContainer--kuI8t";
export var backAndForthRight = "styles-module--backAndForthRight--ZrrTn";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--sEhzI";
export var animateBg = "styles-module--animateBg--A0Mr8";
import React from 'react';
import { Section, Link, Image, Text, Breadcrumbs } from 'components';
import * as styles from './styles.module.scss';

const SingleColumnText = (props) => {
  const { data, isFirstSlice, breadcrumbs, location } = props;
  const { primary } = data;
  const { theme, title, body_text: bodyText, link, link_title: linkTitle, tagline } = primary;

  const titleHtml = title?.html;
  const titleText = title?.text;
  const bodyTextHtml = bodyText?.html;
  const bodyTextText = bodyText?.text;
  const taglineText = tagline?.text;
  const taglineHtml = tagline?.html;
  const hasCta = link?.url;

  // Determine background color
  const themeStyles = {
    Black: 'black',
    'Off-Black': 'offBlack',
    White: 'white',
    'Off-White': 'offWhite',
    Blue: 'blue',
  };

  const hasBreadcrumbs = breadcrumbs.length > 0 && breadcrumbs[0]?.breadcrumb_link?.url !== null;

  return (
    <Section
      className={themeStyles[theme] || 'black'}
      containerClassName={styles.container}
      sliceName="SingleColumnText"
    >
      {isFirstSlice && hasBreadcrumbs && (
        <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={{ uid: location?.pathname, title: titleText }} />
      )}
      {taglineText && <Text html={taglineHtml} h5 className={styles.tagline} />}
      <Text html={titleHtml} h2 className={styles.title} />
      {bodyTextText && <Text html={bodyTextHtml} p3 className={styles.content} />}
      {hasCta && (
        <Link to={link?.url} className={`cta ${styles.cta}`} withArrow>
          {linkTitle?.text !== '' ? linkTitle?.text : 'Explore'}
        </Link>
      )}
    </Section>
  );
};

export default SingleColumnText;

// extracted by mini-css-extract-plugin
export var card = "styles-module--card--+dQEY";
export var image = "styles-module--image--ERqjZ";
export var verticalVariant = "styles-module--verticalVariant--K84lY";
export var excerpt = "styles-module--excerpt--GWoEz";
export var title = "styles-module--title--Cfxz2";
export var details = "styles-module--details--qxhv4";
export var category = "styles-module--category--7PP5c";
export var imageLink = "styles-module--imageLink--DXZSw";
export var largeImage = "styles-module--largeImage--+ANQR";
export var textContainer = "styles-module--textContainer--8ACL1";
export var backAndForthRight = "styles-module--backAndForthRight--5JxEb";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--GHLoG";
export var animateBg = "styles-module--animateBg--9Ynfe";
// extracted by mini-css-extract-plugin
export var container = "styles-module--container--LnF+d";
export var centerContainer = "styles-module--centerContainer--Z-GsU";
export var logo = "styles-module--logo--TNO3g";
export var navContainer = "styles-module--navContainer--gCycE";
export var link = "styles-module--link--Xh0p6";
export var socialLinksContainer = "styles-module--socialLinksContainer--Jnmwj";
export var socialIcon = "styles-module--socialIcon--PqQ6x";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--4Cj+8";
export var button = "styles-module--button--ILyp1";
export var blackHeader = "styles-module--blackHeader--tBFg9";
export var backAndForthRight = "styles-module--backAndForthRight--o57QT";
export var animateBg = "styles-module--animateBg--ZY9uR";
import React, { useState, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Link, Text, Image, Video, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TwoColsTextMedia = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, body_text: bodyText } = primary;

  const staticData = useStaticQuery(graphql`
    query TeamProfilesQuery {
      prismicSettings {
        data {
          team_profiles {
            profile_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: CONSTRAINED, placeholder: BLURRED, width: 500)
                }
              }
            }
            name {
              html
              text
            }
            role {
              html
              text
            }
            linkedin {
              url
            }
            email {
              html
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  `);

  const items = staticData?.prismicSettings?.data?.team_profiles;

  // Determine background color
  const themeStyles = {
    Black: { bg: 'black', arrow: 'white' },
    'Off-Black': { bg: 'offBlack', arrow: 'white' },
    White: { bg: 'white', arrow: 'gradient' },
    'Off-White': { bg: 'offWhite', arrow: 'gradient' },
  };

  // VIDEO CONTROLS
  const [visible, setVisible] = useState(false);

  // REGULAR SLICE TYPE
  return (
    <Section className={`black ${styles.section}`} containerClassName={styles.container} sliceName="TeamProfiles">
      <OnVisible className={styles.textContainer} visibleClassName={styles.textContainerVisible}>
        {title?.html && <Text html={title.html} h4 className={styles.title} />}
        {bodyText?.html && <Text html={bodyText.html} p2 className={styles.bodyText} />}
      </OnVisible>
      <div className={styles.profilesContainer}>
        {items?.length > 0 &&
          items?.map((item, index) => (
            <Link className={styles.profile} to={item?.link?.url} key={item?.name?.text}>
              <OnVisible
                className={styles.imageContainer}
                visibleClassName={styles.imageContainerVisible}
                threshold={0.2}
              >
                <Image className={styles.image} image={item?.profile_image} />
              </OnVisible>
              <Text html={item?.name?.html} h5 className={styles.name} visibleClassName={styles.nameVisible} />
              <Text html={item?.role?.html} p3 className={styles.role} visibleClassName={styles.roleVisible} />
              {(item?.linkedin?.url || item?.email?.text) && (
                <OnVisible className={styles.socials} visibleClassName={styles.socialsVisible}>
                  {item?.linkedin?.url && <Link to={item?.linkedin?.url || '/'} className={styles.linkedin} />}
                  {item?.email?.text && <a href={`mailto:${item?.email?.text}`} className={styles.email} />}
                </OnVisible>
              )}
            </Link>
          ))}
      </div>
    </Section>
  );
};

export default TwoColsTextMedia;

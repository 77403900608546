import React, { useEffect, Fragment } from 'react';
import { use100vh } from 'react-div-100vh';
import { animate, spring, stagger } from 'motion';
import { Link, Logo } from 'components';
import * as styles from './styles.module.scss';

const Navigation = (props) => {
  const { classNamePrefix = 'main', headerLinks, socialLinks, active, setActive, location } = props;
  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';
  const parentLinks = headerLinks.filter((link) => !link.parent_link);

  // 100VH FOR ALL BROWSERS INCLUDING SAFARI
  const height = use100vh();

  useEffect(() => {
    // ANIMATIONS
    animate(
      '#nav-container',
      {
        opacity: active ? 1 : 0,
        display: active ? 'block' : 'none',
      },
      {
        duration: 0.5,
        easing: 'ease-in',
        display: { delay: active ? 0 : 1, duration: 0 },
      }
    );

    animate(
      '#center-container',
      {
        opacity: 1,
      },
      {
        delay: 0.2,
        duration: 1.2,
        easing: 'ease-in',
      }
    );

    animate(
      '.icon',
      {
        y: active ? 0 : 20,
        opacity: active ? 1 : 0,
      },
      {
        delay: active ? stagger(0.3, { start: 0.3 }) : 0,
        easing: spring(),
      }
    );

    animate(
      '#nav-logo',
      {
        transform: active && [
          `scale(2.8) translateY(-6px)`,
          `scale(3) translateY(-2px)`,
          `scale(2.8) translateY(-6px)`,
        ],
        opacity: active ? 1 : 0,
      },
      {
        opacity: { duration: 1, easing: 'ease-in' },
        transform: { repeat: Infinity },
        easing: spring({ mass: 100, velocity: 10, stiffness: 10, damping: 100, restSpeed: 1, restDistance: 0.1 }),
      }
    );

    animate(
      '.nav-link',
      {
        scale: active ? 1 : 0.95,
        y: active ? 0 : -20,
        opacity: active ? 1 : 0,
      },
      {
        delay: active ? stagger(0.2, { start: 0.2 }) : 0,
        easing: spring(),
      }
    );
  }, [active]);

  // DISABLES SCROLL ON ACTIVE
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [active]);

  return (
    <nav
      id="nav-container"
      className={`${className} ${styles.container} ${location?.pathname === '/' && styles.blackHeader}`}
      style={{ height }}
    >
      <div id="center-container" className={styles.centerContainer}>
        <Logo icon id="nav-logo" className={styles.logo} />
        <div className={styles.navContainer}>
          {parentLinks &&
            parentLinks.map((link) => {
              const url = link?.link?.url;
              const text = link?.link_text?.text;
              const currentPage = location?.pathname;
              const isCurrent = currentPage === url;
              const handleCurrentClick = (event) => {
                event.preventDefault();
                setActive(false);
              };
              return (
                <Link
                  key={text}
                  className={`nav-link ${styles.link} ${className}-link ${link.className || ''}`}
                  to={url}
                  onClick={isCurrent ? handleCurrentClick : () => {}}
                >
                  {text}
                </Link>
              );
            })}
          {/* <Link to="/invest" className={`nav-link button ${styles.button}`}>
            Invest
          </Link> */}
        </div>
        <div id="social-nav-container" className={styles.socialLinksContainer}>
          {socialLinks.length > 0 &&
            socialLinks?.map((item) => (
              <Fragment key={item?.link?.url || item?.alt}>
                {item?.icon}
                <Link
                  to={item?.link?.url || '/'}
                  className={`icon ${styles.socialIcon} ${item?.class}`}
                  alt={item?.alt}
                />
              </Fragment>
            ))}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;

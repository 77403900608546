// extracted by mini-css-extract-plugin
export var text = "styles-module--text--w++5r";
export var h1 = "styles-module--h1--0LCvG";
export var h2 = "styles-module--h2--Wf7-Z";
export var h3 = "styles-module--h3--pQMD2";
export var h4 = "styles-module--h4--ZA6Bh";
export var h5 = "styles-module--h5--UjdpX";
export var h6 = "styles-module--h6--2lPMr";
export var p1 = "styles-module--p1--KGh42";
export var p2 = "styles-module--p2--x4bDY";
export var p3 = "styles-module--p3--t9xW4";
export var backAndForthRight = "styles-module--backAndForthRight--bVUrC";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--dxGE9";
export var animateBg = "styles-module--animateBg--Bnjqx";
// extracted by mini-css-extract-plugin
export var section = "styles-module--section--ac5hh";
export var container = "styles-module--container--tDatP";
export var category = "styles-module--category--Gxpnw";
export var animateBg = "styles-module--animateBg--7jwD5";
export var categoryTitle = "styles-module--categoryTitle--gIRzk";
export var navContainer = "styles-module--navContainer--nPfyp";
export var linksContainer = "styles-module--linksContainer--bdXHP";
export var searchContainer = "styles-module--searchContainer--APFjw";
export var searchIcon = "styles-module--searchIcon--h9BHr";
export var wobbleRightAndLeft = "styles-module--wobbleRightAndLeft--dJo5f";
export var hamburger = "styles-module--hamburger--bxesE";
export var hamburgerOpen = "styles-module--hamburgerOpen--lPNfF";
export var searchBarContainer = "styles-module--searchBarContainer--uo1XP";
export var activeContainer = "styles-module--activeContainer--LdGLU";
export var backAndForthRight = "styles-module--backAndForthRight--TMyOF";
import React, { useEffect, useState, Fragment } from 'react';
import { Section, Text, Image, Link } from 'components';
import { ReactComponent as Twitter } from 'images/icons/icon-twitter.svg';
import { ReactComponent as LinkedIn } from 'images/icons/icon-linkedin.svg';
import { ReactComponent as Instagram } from 'images/icons/icon-instagram.svg';
import * as styles from './styles.module.scss';

const BlogPostHeader = ({ author, category, date = '', title, dataString, image }) => {
  const categoryName = category?.document?.data?.category_name;
  const categoryLink = category?.document?.uid;

  const authorData = author?.document?.data || {};
  const { author_name: authorName, author_image: authorImage, twitter, linkedin, instagram } = authorData;

  // time to read
  const [minsToRead, setMinsToRead] = useState(0);
  useEffect(() => {
    const wordsPerMinute = 200;
    const textLength = dataString && dataString.split(' ').length; // Split by words
    if (textLength > 0) {
      setMinsToRead(Math.ceil(textLength / wordsPerMinute));
    }
  }, [dataString]);

  // socials
  const socialLinks = [
    { comp: <Twitter className={`social-icon ${styles.icon}`} />, link: twitter?.url },
    { comp: <LinkedIn className={`social-icon ${styles.icon}`} />, link: linkedin?.url },
    { comp: <Instagram className={`social-icon ${styles.icon}`} />, link: instagram?.url },
  ];

  return (
    <>
      <Section className={styles.section} containerClassName={styles.container} bgImage={image}>
        <Link to={`/insights/${categoryLink}`} className={styles.category}>
          {categoryName?.text}
        </Link>
        {title?.text && <Text html={title.html} h3 className={styles.title} />}
        {authorData && (
          <div className={styles.authorDetails}>
            {authorImage?.url && <Image image={authorImage} className={styles.authorImage} />}
            <div className={styles.authorText}>
              {authorName?.text && <span className={styles.author}>{authorName.text}</span>}
              {date && (
                <div className={styles.details}>
                  {date && <span className={styles.date}>{date}</span>}
                  {minsToRead !== 0 && date && <span className={styles.dot}>•</span>}
                  {minsToRead !== 0 && <span className={styles.readtime}>{minsToRead} min read</span>}
                </div>
              )}
              <div className={styles.socials}>
                {socialLinks.map((item, index) => {
                  const { comp, link } = item;
                  return (
                    <Fragment key={link}>
                      {link && (
                        <Link to={link} className={styles.iconContainer} style={{ transitionDelay: `${index * 0.2}s` }}>
                          {comp}
                        </Link>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Section>
    </>
  );
};

export default BlogPostHeader;

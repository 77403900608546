import React, { useState, useEffect } from 'react';
import './styles.scss';

const Cursor = () => {
  // set the starting position of the cursor outside of the screen
  const [hoverAnimation, setHoverAnimation] = useState(false);
  const [footerCta, setFooterCta] = useState(false);

  useEffect(() => {
    let clientX = -100;
    let clientY = -100;
    let target = {
      closest: () => {},
    };
    const innerCursor = document.querySelector('.cursor');
    document.addEventListener(
      'mousemove',
      (event) => {
        ({ clientX, clientY, target } = event);
      },
      { passive: true }
    );
    const render = () => {
      const cursorWidth = innerCursor.offsetWidth;
      const cursorHeight = innerCursor.offsetHeight;
      innerCursor.style.left = `${clientX - cursorWidth / 2}px`;
      innerCursor.style.top = `${clientY - cursorHeight / 2}px`;

      // cursor types
      const isFooterCta = target.closest('.footer-cta');
      const addHoverAnimation = target.closest('a') || target.closest('button');

      if (isFooterCta && !footerCta) {
        setFooterCta(true);
      }

      if (!isFooterCta && footerCta) {
        setFooterCta(false);
      }

      // toggle hover animation
      if (addHoverAnimation && !hoverAnimation) {
        setHoverAnimation(true);
      }

      if (!addHoverAnimation && hoverAnimation) {
        setHoverAnimation(false);
      }

      requestAnimationFrame(render);
    };
    // Run custom animation function
    requestAnimationFrame(render);
    // Remove event listener on clean up
    // return () => {
    //   document.removeEventListener('mousemove', setMousePosition, false);
    // };
  }, [hoverAnimation, footerCta]);

  // cursor class names
  const hoverClass = hoverAnimation ? 'hovering' : '';
  const footerCtaClass = footerCta ? 'white-cursor' : '';

  return <div className={`cursor ${hoverClass} ${footerCtaClass}`} />;
};

export default Cursor;
